import gql from 'graphql-tag';

export class Gqls {

   static getSubscriptionBspRules = gql`
   query($country: SubscriptionBspRuleGetByCountryType!){
    getSubscriptionBspRules(input:$country)
    {
     consumerFamilyMember,
      dataPrivacy,
      nTer,
      tenantBusinessProviders,
      dataRetention,
      packageValidity,
      paymentWithAppliedFee,
      allowAdditionalEmployee,
      allowAdditionalPromotion,
      allowAdditionalBranchOffice,
      allowAdditionalTenantBusiness,
      allowAdditionalJobPosting,
      promotionValidity,
      additionalEmployeeCharges,
      reportsPeriod,
      additionalPromotionCharges,
      additionalJobPostingCharges,
      busNet,
      deals,
      employeesCount,
      businessPrivateMessaging,
      branchOffices,
      consumerPrivateMessaging,
      consolidatedCalendar,
      dataLimit,
      businessVerification,
      promotions,
      packageId,
      jobSearchApply,
      jobPostingValidity,
      additionalTenantBusinessCharges,
      tudoPortionOfConsumerTip,
      packageName,
      additionalBranchOfficeCharges,
      jobPostings,
      tasksEvents,
      showAdds,
      serviceAppointments,
      myNet,
      packageMonthlyPrice,
      packageAnnualPrice
      
    }
  }
   ` ;

   static login = gql`
mutation($user:SessionInputType!) {
    loginUser(input:$user) {
      token,
      user {
        aclRoleId,
        confirmationSentAt,
        confirmationToken,
        confirmedAt,
        currentSignInAt,
        email,
        failedAttempts,
        id,
        isBsp,
        isVerified,
        country {
          name,
          id,
          code,
          isdCode,
          currencyCode,
          currencySymbol,
          capital,
          contactInfo,
          officialName,
        },
        language{
          id,
          code,
          isActive,
          name
        },
        lockedAt,
        meta,
        mobile,
        platformTermsAndConditionId,
        profile,
        resetPasswordSentAt,
        resetPasswordToken,
        scopes,
        signInCount,
        statusId,
        token,
        unlockToken,
        userAddress {
          id,
          address,
          geo,
          slug
        }
      }
    }
  }
`;

   static sendToken =  gql`
          mutation($token: SessionSendInputType!) {
            sendToken(input:$token) {
                   confirmationSentAt,
                              confirmationToken,
                              confirmedAt,
                              currentSignInAt,
                              email,
                              failedAttempts,
                              id,
                              isBsp,
                              isVerified,
                              country {
                                  name,
                                  id,
                                  code,
                                  isdCode,
                                  currencyCode,
                                  currencySymbol,
                                  capital,
                                  contactInfo,
                                  officialName,
                              },
                              language{
                                id,
                                code,
                                isActive,
                                name
                              },
                              lockedAt,
                              meta,
                              mobile,
                              platformTermsAndConditionId,
                              profile,
                              resetPasswordSentAt,
                              resetPasswordToken,
                              scopes,
                              signInCount,
                              statusId,
                              token,
                              unlockToken,
                              userAddress {
                                id,
                                address,
                                geo,
                                slug
                              }
                }
              }
   `;

   static forgetPassword = gql`
                mutation($forgotPass: SessionForgetInputType!) {
                  forgetPassword (input: $forgotPass){
                        aclRoleId,
                        confirmationSentAt,
                        confirmationToken,
                        confirmedAt,
                        currentSignInAt,
                        email,
                        failedAttempts,
                        id,
                        isBsp,
                        isVerified,
                        country {
                        name,
                          id,
                          code,
                          isdCode,
                          currencyCode,
                          currencySymbol,
                          capital,
                          contactInfo,
                          officialName,
                        },
                        language{
                          id,
                          code,
                          isActive,
                          name
                        },
                        lockedAt,
                        meta,
                        mobile,
                        platformTermsAndConditionId,
                        profile,
                        resetPasswordSentAt,
                        resetPasswordToken,
                        scopes,
                        signInCount,
                        statusId,
                        token,
                        unlockToken,
                        userAddress {
                          id,
                          address,
                          geo,
                          slug
                        }
                  }
              }
   `;
  
   static registerConfirm = gql`
          mutation ($user:RegisterConfirmationInputType!){
            registerConfirmation(input:$user) {
                        aclRoleId, 
                        confirmationSentAt,
                        confirmationToken,
                        confirmedAt,
                        currentSignInAt,
                        email,
                        failedAttempts,
                        id,
                        isBsp,
                        isVerified,
                        country {
                            name,
                            id,
                            code,
                            isdCode,
                            currencyCode,
                            currencySymbol,
                            capital,
                            contactInfo,
                            officialName,
                        },
                        language{
                          id,
                          code,
                          isActive,
                          name
                        },
                        lockedAt,
                        meta,
                        mobile,
                        platformTermsAndConditionId,
                        profile,
                        resetPasswordSentAt,
                        resetPasswordToken,
                        scopes,
                        signInCount,
                        statusId,
                        token,
                        unlockToken,
                        userAddress {
                          id,
                          address,
                          geo,
                          slug
                        }
            }
          } 
   `;
  
   static registration = gql`
        mutation($user: UserInputType!) {
          createUser(input:$user){
            confirmationSentAt,
            confirmationToken,
            confirmedAt,
            currentSignInAt,
            email,
            failedAttempts,
            id,
            isBsp,
            isVerified,
            country {
              name,
              id,
              code,
              isdCode,
              currencyCode,
              currencySymbol,
              capital,
              contactInfo,
              officialName,
            },
            language{
                    id,
                    code,
                    isActive,
                    name
                  },
            lockedAt,
            meta,
            mobile,
            platformTermsAndConditionId,
            profile,
            resetPasswordSentAt,
            resetPasswordToken,
            scopes,
            signInCount,
            statusId,
            token,
            unlockToken,
            userAddress {
                id,
                address,
                geo,
                slug
              }
            }
          }
   `;
  
  static getcoutries = gql`
      query countries {
        countries{
          name,
          id,
          code,
          isdCode,
          currencyCode,
          currencySymbol,
          capital,
          contactInfo,
          officialName,
      }
    }
  `;
  
  static getLanguage = gql`
      query {
        languages {
          id,
          code,
          isActive,
          name
        }
      }
  `;

  static getDropDown = gql`
        query {
          dropdowns{
              id,
              name,
              slug,
              type
          }
      }`;

  static getBusinessTyeps = gql`
  query {
    businessTypes{
      id,
      name
    }
  }
  `;

  static getLicenceAuthorityByCountryId = gql`
      query($input: LicenceIssueingAuthoritiesGetType!) {
        licenceIssuingAuthorities(input: $input) {
          id,
          name,
          isActive,
          country {
            id,
            name,
            code,
            isdCode
          }
        }
      }
  `;

  static getServiceByCountry = gql`
      mutation ($serviceMeta: CountryServiceGroupType!) {
        servicesByCountry(input: $serviceMeta) 
      }
  `;

  static createBusiness = gql`
            mutation($bspUser: BusinessInputType!) {
              createBusiness(input: $bspUser) {
                agreeToPayForVerification, 
                      employeesCount,
                      id,
                      isActive,
                      isVerified,
                      name,
                      phone,
                      profilePictures,
                      rating,
                      ratingCount,
                      termsAndConditions,
                      user {
                              aclRoleId,
                              confirmationSentAt,
                              confirmationToken,
                              confirmedAt,
                              currentSignInAt,
                              email,
                              failedAttempts,
                              id,
                              isBsp,
                              isVerified,
                              country {
                              name,
                                id,
                                code,
                                isdCode,
                                currencyCode,
                                currencySymbol,
                                capital,
                                contactInfo,
                                officialName,
                              },
                              language{
                                id,
                                code,
                                isActive,
                                name
                              },
                              lockedAt,
                              meta,
                              mobile,
                              platformTermsAndConditionId,
                              profile,
                              resetPasswordSentAt,
                              resetPasswordToken,
                              scopes,
                              signInCount,
                              statusId,
                              token,
                              unlockToken,
                              userAddress {
                                id,
                                address,
                                geo,
                                slug
                              }
                    },
                }
            }
  `;

  
  static getBusinessByUser =  gql`
        mutation($bspId: BusinessDeleteType!) {
          getBusinessByUserId(input: $bspId) {
            id
            name
            employeesCount
            phone
            description
            branches {
            name,
              formattedBranchServices
                branchServices{
                  id,
                  isActive
                  countryService{
                    service{
                      serviceGroup{
                        name,
                        id
                      },
                      id,
                      name
                    }
                  }
                }
              employeesCount,
              employees{
                id,
                allowedAnnualAnsenceHrs,
                contractBeginDate,
                employeeRoleId,
                vehicleDetails,
                employeeRoleInOrg,
                contractBeginDate,
                user{
                  profile,
                  address
                },
                userAddress{
                  address,
                  geo
                },
                branch{
                  name
              },
                personalIdentification
                allowedAnnualAnsenceHrs
                
              }
              description
              phone
              address
              estYear
              name
              business {
                id
                name
              }
              businessType{
                id
                name
              },
              licenceNo
              licenceIssuingAuthority {
                id
                name
                country {
                  id
                  name
                }
              }
              customLicenseIssuingAuthority
              country {
                id
                code
                contactInfo
                currencyCode
                currencySymbol
                isActive
                name
              }
              id
              licenceExpiryDate
              suretyBonded
              generalLiabilityInsured
              countryId
              otherDetails
              licencePhotos
              personalIdentification
              profilePictures
            
            }
          }
        }
  `;

  static getEmployeementBYUser = gql`
      mutation($input: EmployeeByUserIdType!){
        employeesByUserId(input:$input) {
          id,
        allowedAnnualAnsenceHrs,
        vehicleDetails,
        user{
          aclRoleId,
          address,
          profile
        },
        employeeSetting{
          insurance,
          qualification,
          vehicle,
          experience
        }
        branch{
      
          name,
          address,
          
          
        }
        employeeStatusId,
        } 
    }
  `;

  static getDashboardItems = gql`
      query {
        dashboardItems {
          id,
          aclRoleId,
          docOrder,
          menu {
            description,
            id,
            images,
            isActive,
            slug,
            type,
            title
          },
          menuOrder,
        }
      }
  `;

  static getMetaDataForBSP = gql`
   mutation($metaType:BspMetaGetType!){
    getBspMeta(input:$metaType){
      statistics
      }
    }
  `;

  static getMetaDataForCMR = gql` 
  mutation($metaType:CmrMetaGetType!){
    getCmrMeta(input:$metaType){
      statistics
    }
  }
  `;

  static logOut = gql`
    mutation($input: SessionLogoutType!) {
      logoutUser(input: $input) {
        meta
      }
    }
  `;

  static getJobsForBsp = gql`
  mutation ($bsp_detail : JobGetBspType! ){
    getJobsForBsp(input: $bsp_detail){
                           id,
                           ewdInt
                           chatGroupId
                           title,
                           jobCategoryId,
                           branchService,
                           dynamicFields,
                           locationDest,
                           employeeId,
                           jobStatusId,
                           locationSrc,
                           locationDest,
                           description,
                           locationDestZoneId,
                           arriveAt,
                           cancelReason,
                           jobBspStatusId,
                           jobCmrStatusId,
                           invoiceId,
                           invoiceAmount,
                           cmr {
                             profile,
                             mobile
                           },
                           branch {
                             profilePictures,
                             name
                           },
                           cost,
                           jobAddress,
                           gallery,
                           ticketNo
                           dayLight 
                           reasonForTimeChange
                           waitingArriveAt         
                         }
  }
  `;

  static getJobsForCmr = gql`
  mutation($jobStatus:JobGetCmrType!) {
    getJobsForCmr(input: $jobStatus) {
      id,
      ewdInt
      chatGroupId
      title,
      dynamicFields,
      locationDest,
      locationSrc,
      employeeId,
      jobStatusId,
      description,
      jobCategoryId,
      cmrToBspRating,
      calledAt,
      arriveAt,
      insertedBy,
      approvedBy,
      branchServiceId,
      expectedWorkDuration,
      gallery,
      branchService,
      cancelReason,
      cost,
      jobBspStatusId,
      jobCmrStatusId,
      invoiceId,
      invoiceAmount,
      branch {
        profilePictures,
        name
      },
      jobAddress,
      ticketNo
      dayLight
      reasonForTimeChange
      waitingArriveAt
      deal {
        id
        amount
        beginDate
        description
        endDate
        expireAfterAmount
        expiryCount
        isCombined
        isPercentage
        maxUserCount
        photos
        promotionStatus {
          id
          description
          title
        }
        serviceIds
        termAndConditionIds
        title
        validAfterAmount
        value
        zoneIds
        radius
        discountType {
          id
          name
        }
        branch {
          id
          name
          address
          geo
          rating  
          profilePictures
          business {
            id
            name
            description
          }
        }
        services
      }
    }
  }
  `;

  static getBranches = gql`
  query($input:BranchListingGetType){ 
    branches(
      input: $input
    ){
      totalPages,
      totalEntries,
      branches{ 
        name,
        formattedBranchServices,
          branchServices{
            id,
            isActive
            countryService{
              service{
                serviceGroup{
                  name,
                  id
                },
                id,
                name
              }
            }
          }
        employeesCount,
        employees{
          id,
          allowedAnnualAnsenceHrs,
          contractBeginDate,
          employeeRoleId,
          vehicleDetails,
          employeeRoleInOrg,
          contractBeginDate,
          user{
            profile,
            address
          },
          userAddress{
            address,
            geo
          },
          branch{
            name
        },
          personalIdentification
          allowedAnnualAnsenceHrs
          
        }
        description
        phone
        address
        estYear
        name
        business {
          id
          name
        }
        businessType{
          id
          name
        },
        licenceNo
        licenceIssuingAuthority {
          id
          name
          country {
            id
            name
          }
        }
        customLicenseIssuingAuthority
        country {
          id
          code
          contactInfo
          currencyCode
          currencySymbol
          isActive
          name
        }
        id
        licenceExpiryDate
        suretyBonded
        generalLiabilityInsured
        countryId
        otherDetails
        licencePhotos
        personalIdentification
        profilePictures
        status{
          id,
          title
        }
        geo
       }
         }
    
      }
  `;

  static updateBranchStatus = gql`
  mutation($input:BranchActivateType! ){
    makeBranchActive(input:$input){
      id,
      name,
      geo,
      status {
        id
      },
      settings {
        providesHomeService
        providesOnDemand
        providesWalkin
      },
      business{
        name
      }
    }
  }
  
  `;

  static getBranch = gql`
  query($input: BranchDeleteType!){
    getBranch(input: $input){
      name,
      formattedBranchServices,
          branchServices{
            id,
            isActive
            countryService{
              service{
                serviceGroup{
                  name,
                  id
                },
                id,
                name
              }
            }
          }
        employeesCount,
        employees{
          id,
          allowedAnnualAnsenceHrs,
          contractBeginDate,
          employeeRoleId,
          vehicleDetails,
          employeeRoleInOrg,
          contractBeginDate,
          user{
            profile,
            address
          },
          userAddress{
            address,
            geo
          },
          branch{
            name
        },
          personalIdentification
          allowedAnnualAnsenceHrs
          
        }
        description
        phone
        address
        estYear
        name
        business {
          id
          name
        }
        businessType{
          id
          name
        },
        licenceNo
        licenceIssuingAuthority {
          id
          name
          country {
            id
            name
          }
        }
        customLicenseIssuingAuthority
        country {
          id
          code
          contactInfo
          currencyCode
          currencySymbol
          isActive
          name
        }
        id
        licenceExpiryDate
        suretyBonded
        generalLiabilityInsured
        countryId
        otherDetails
        licencePhotos
        personalIdentification
        profilePictures
        status{
          id,
          title
        },
        geo
      
       }
         
    
      
    }
  `;

}