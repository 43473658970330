import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { ConditionalExpr } from '@angular/compiler';
import { mergeMapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.usePublicVapidKey('BA365isOJSxC6PMfrDKnFjbNiM4zT1Iy5zVPoZegu5zz0mXohl--HXCgppLzxQeSDOPG3kpuOHPA2izozf-RcR4');
  }

  requestPermission() {
    return new Promise((resolve, reject) => {
      try {
        this.angularFireMessaging.requestToken
        .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
        .subscribe(
          (token) => {
              token = token == null ? "Doesn't have permission to generate token" : token;
              resolve(token);
              console.log("firebase token generated successfully");
          },
          (err) => {
              console.error('Unable to get permission to notify.', err);
              resolve("Doesn't have permission to generate token");
          }
        );
      } catch {
        this.requestPermission();
      }
    }
    );
  }



  receiveMessage() {
    // this.angularFireMessaging.messages.subscribe(
    //   (payload) => {
    //     console.log("new message received. ", payload);
    //     this.currentMessage.next(payload);
    //   })

    this.angularFireMessaging.onMessage(payload => {
      console.log(payload, "notification");
    });

  }

  backgroundMessage(message) {
    console.log(message);
  }

  async getToken() {
   return  localStorage.getItem('fcmToken');
  }
}
