import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  constructor(
    private apollo: Apollo,
    private fuseSplashScreenService: FuseSplashScreenService
  ) { 
  }

  // get query data from the graphQL
  query(query,variable = {},showLoader = true) {
    
    if(showLoader == true) {
      this.fuseSplashScreenService.show();
    }

    return this.apollo.watchQuery<any>({
      query: query,
      errorPolicy:'all',
      variables:variable
    }).valueChanges.pipe((val) => { 
      if(showLoader == true) {
        this.fuseSplashScreenService.hide();
      }
      return val;
    });
  }

  // run mutation with the variable 
  mutation(mutation,variable) {
    this.fuseSplashScreenService.show();
    return this.apollo.mutate<any>({
      mutation: mutation,
      variables:variable,
      errorPolicy: 'all'
    }).pipe((val) => { 
      this.fuseSplashScreenService.hide();
      return val;
    });
  }
}
