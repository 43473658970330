import { Injectable } from '@angular/core';
import { AuthService } from 'app/utils/services/auth/auth.service';
import { GraphqlService } from 'app/utils/graphql/services/graphql.service';
import { Gqls } from 'app/utils/graphql/gql';
@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(
    private authService: AuthService,
    private graphQLService: GraphqlService
  ) { 

  }

 async getBusinessData() {
    let data =  {"bspId": {
      "id": parseInt(this.authService.getUser().id),
    }};
    
    let businessdata  = await  this.graphQLService.mutation(Gqls.getBusinessByUser,data).toPromise();   
    return businessdata.data.getBusinessByUserId;
  }

 async getEmployeementBYUser() {
    let data =  {
      "input": {"userId": parseInt(this.authService.getUser().id)}
    };
    
    let getEmployeementBYUser  = await  this.graphQLService.mutation(Gqls.getEmployeementBYUser,data).toPromise();   
    return getEmployeementBYUser.data.employeesByUserId;
  }


}
