<div class="image-modal-wrraper" *ngIf="isOpen == true">
    <div class="background-layer" ></div>
    <div class="image-wrapper" >
        <img [src]="images[selectedIndex]['original']" alt="" srcset="">
    </div>
    <div class="next" *ngIf="images.length > 0 && selectedIndex != (images.length - 1)" >
        <mat-icon (click) ="next()" >arrow_forward_ios        </mat-icon>
    </div>
    <div class="prev" *ngIf="selectedIndex != 0">
        <mat-icon (click) ="prev()">arrow_back_ios</mat-icon>
    </div>
    <div class="close">
         <mat-icon (click)="hide()">close</mat-icon>
    </div>
</div>