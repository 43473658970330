// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let server = "https://live.tudo.app/";

export const environment = {
    production: true,
    hmr: false,
    server: server,
    graphQLHost: server + "graphiql",
    fileUploadURL: server + "files",
    firebase: {
        apiKey: "AIzaSyAn46p8Srujy3Lo5gJTOa7nXtUNqxwERv0",
        authDomain: "tudo-7ec9a.firebaseapp.com",
        databaseURL: "https://tudo-7ec9a.firebaseio.com",
        projectId: "tudo-7ec9a",
        storageBucket: "tudo-7ec9a.appspot.com",
        messagingSenderId: "931518085086",
        appId: "1:931518085086:web:8831784cea973ae1564f4f",
        measurementId: "G-DR2Q3FFNE2",
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
