import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { GraphQLModule } from './graphql.module';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FooterModule } from './layout/components/footer/footer.module';
import { AgmCoreModule } from '@agm/core';
import { AuthGuard } from './guard/auth/auth.guard';
import { LanModule } from './utils/pipe/lan/lan.module';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import {ImageModalComponent} from './utils/components/image-modal/image-modal.component';
import {ImageModalService} from './utils/components/image-modal/image-modal.service';

const appRoutes: Routes = [
    {
        path        : '',
        loadChildren: () => import('./main/pages/authentication/login-2/login-2.module').then(m => m.Login2Module),
        canActivate:[AuthGuard]
    },
    {
        path        : 'business',
        loadChildren: () => import('./modules/branches/branches.module').then(m => m.BranchesModule),
        canActivate:[AuthGuard]
    },
    // {
    //     path        : 'listing',
    //     loadChildren: () => import('./modules/job-listing/job-listing.module').then(m => m.JobListingModule),
    //     canActivate:[AuthGuard]
    // },
    {
        path        : 'auth',
        loadChildren: () => import('./main/pages/authentication/login-2/login-2.module').then(m => m.Login2Module)
    },
    // {
    //     path        : 'business',
    //     loadChildren: () => import('./modules/bsp/bsp.module').then(m => m.BspModule),
    //     canActivate:[AuthGuard]
    // },
    
    {
        path        : 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'ui',
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    },
    {
        path        : 'documentation',
        loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule)
    },
    {
        path      : '**',
        redirectTo: 'apps/dashboards/analytics'
    }
];


const maskConfigFunction: () => Partial<IConfig> = () => {
    return {
      validation: false,
    };
  };
   

@NgModule({
    declarations: [
        AppComponent,
        ImageModalComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        GraphQLModule,
        ToastrModule.forRoot(), 
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        FooterModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDSv2EULSFi3Me30rlKuhsfcdYB52dYnsg',
            libraries:['places']
        }),
        LanModule, // language transformation in app
        NgxMaskModule.forRoot(maskConfigFunction),
    ],
    providers:[
        ImageModalService
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
