
/* get the navigation URLs throught the menu slug */
export class NavigationURLs {

    static cmrNavigationURLS = {
        "RSVP": () => 'listing/cmr/RSVP',
        "calendar":() => '/calendar',
        "deals": () => '/deals',
        "eventer":() => '/eventer',
        "my_net": () => '/my_net',
        "n_ter":() => '/n_ter',
        "payments": () => 'listing/cmr/payments',
        "reports":() => '/reports',
        "scheduled": () => 'listing/cmr/scheduled',
    };

    static bspNavigationURLS = {
        "RSVP": (branchId) => 'listing/bsp/' + branchId + '/RSVP',
        "accounter": (branchId) => 'listing/bsp/' + branchId + '/accounter',
        "bus_net": () => '/bus_net',
        "business_setting":() => '/business_setting',
        "calendar": () => '/calendar',
        "eventer": () => '/eventer',
        "leads":() => '/leads',
        "myemployees": () => '/myemployees',
        "n_ter": () => '/n_ter',
        "promos":() => '/promos',
        "prospects":() => '/prospects',
        "reports":() => '/reports',
        "scheduled": (branchId) => 'listing/bsp/' + branchId + '/scheduled',
      }

    /* get CMR URLS for the give tiles slug */
    static getCMRURLBySlug(slug) {
        if(NavigationURLs.cmrNavigationURLS[slug]) {
            return NavigationURLs.cmrNavigationURLS[slug]();
          } else {
              return () => '/';
          }
    }

    /* get BSP URLS for the give tiles slug */
    static getBSPURLBySlug(slug,branchId) {
      
         if(NavigationURLs.bspNavigationURLS[slug]) {
           return NavigationURLs.bspNavigationURLS[slug](branchId);
         } else {
             return () => '/';
         }
    }

}