import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageModalService {

  imageObserval = new Subject();

  constructor() { }

  open(images:Array<any>,openIndex) {
    this.imageObserval.next({
      images:images,
      selectedIndex:openIndex,
      isOpen:true
    });
  
  }

  close() {
    this.imageObserval.next({
      images:[],
      selectedIndex:0,
      isOpen:false
    });
  }
}
