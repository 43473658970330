import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanPipe } from 'app/utils/pipe/lan/lan.pipe';




@NgModule({
  declarations: [LanPipe],
  imports: [
    CommonModule,
  ],
  exports:[
    LanPipe
  ],
  providers:[
    LanPipe
  ]
})
export class LanModule { 
    
}
