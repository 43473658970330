import { Injectable } from '@angular/core';
import { GraphqlService } from 'app/utils/graphql/services/graphql.service';
import { Gqls } from 'app/utils/graphql/gql';
import { DashboardItem } from 'app/utils/models/dashboard_items';
import { Subject, Subscription } from 'rxjs';
import { BusinessService } from '../business/business.service';
import * as _ from 'lodash';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { NavigationURLs } from 'app/utils/models/navigation_url';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  businessList: Array<any> = [];
  BSPDashboardItems: Array<DashboardItem> = [];
  CMRDashboardItems: Array<DashboardItem> = [];

  private dashboardData = new Subject<any>();
  private cmrMetadata = new Subject<any>();
  private bspMetadata = new Subject<any>();

  private bspSubscription : Subscription;
  private cmrSubscrition : Subscription;

  constructor(
    private graphQlService: GraphqlService,
    private businessService: BusinessService,
    private _fuseNavigationService: FuseNavigationService
  ) { }


  async getIntialData(BSPMenu = true, CRMMenu = true) {
      this._fuseNavigationService.removeNavigationItem('aminDashboard');
      const newCMRNavItem:any = {
        id: 'aminDashboard',
        title: 'Admin',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
          {
            id: 'Branches',
            title: 'Businesses',
            type: 'item',
            icon: 'dashboard',
            url: '/business/list',
          }      
        ]
      };
      
      this._fuseNavigationService.addNavigationItem(newCMRNavItem, 'end');
  
       this.dashboardData.next();
  } 

  /* get menu items for the left side */
  getBranchSidebarData(branch,menu) {
      
      let children = this.BSPDashboardItems.map(tile => {
          return    {
           id: tile.menu.slug,
           title: tile.menu.title,
           type: 'item',
           url: NavigationURLs.getBSPURLBySlug(tile.menu.slug,branch.id)
         };
      });
 
      return children;
   }

  /* get menu items for the left side */
  getCMRSidebarData() {
      
      let children = this.CMRDashboardItems.map(tile => {
          return    {
           id: tile.menu.slug,
           title: tile.menu.title,
           type: 'item',
           url: NavigationURLs.getCMRURLBySlug(tile.menu.slug)
         };
      });
 
      return children;
   }

  async getDashboardItems() {
    return new Promise<Array<DashboardItem>>((resolve, reject) => {

      this.graphQlService.query(Gqls.getDashboardItems).subscribe(result => {
        resolve(result.data.dashboardItems);
      });
    });
  }

  getBSPSubscription() {
    return this.bspMetadata.asObservable();
  }

  getCMRSubscription() {
    return this.cmrMetadata.asObservable();
  }

  getIntialDataSubscription() {
    return this.dashboardData.asObservable();
  }

  async getBSPMetaData(id){
    this.bspSubscription?.unsubscribe();
    let variable = {
      "metaType": {
        "type": "dashboard",
        "employeeId": id,
      }
    };
    this.bspSubscription =   this.graphQlService.mutation(Gqls.getMetaDataForBSP,variable).subscribe(result => {
         
        console.log(result,"metadata"); 
       this.bspMetadata.next(result);
     });
  
  }

  async getCMRMetaData(){
    this.cmrSubscrition?.unsubscribe();
    let variable = {
      "metaType": {
        "type": "dashboard",
      }
    };
    this.cmrSubscrition =   this.graphQlService.mutation(Gqls.getMetaDataForCMR,variable).subscribe(result => {
         
        console.log(result,"metadata cmr"); 
       this.cmrMetadata.next(result);
     });
  }
}
