import { Component, OnInit, HostListener } from '@angular/core';
import { ImageModalService } from './image-modal.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  images:any = [];
  isOpen = false;
  selectedIndex = 0;

  constructor(
    private imageModalService:ImageModalService
  ) { 

  }

  ngOnInit(): void {

     this.imageModalService.imageObserval.subscribe((res:any) => {
          this.images = res.images;
          this.isOpen = res.isOpen;
          this.selectedIndex = res.selectedIndex;
     });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
  }

  hide() {
    this.isOpen = false;
  }

  show() {
    this.isOpen = true;
  }

  next() {
    this.selectedIndex++;
  }

  prev() {
   this.selectedIndex--;
  }

}
