import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ln'
})
export class LanPipe implements PipeTransform {

  trans =  {
    'login': {
      'email': {
        'id': 1,
        'translation': 'Email',
      },
      'password': {
        'id': 2,
        'translation': 'Password',
      },
      'show_password': {
        'id': 3,
        'translation': 'Show Password',
      },
      'hide_password': {
        'id': 4,
        'translation': 'Hide Password',
      },
      'login': {
        'id': 5,
        'translation': 'Log In',
      },
      'signup': {
        'id': 6,
        'translation': 'Sign Up',
      },
      'info_text': {
        'id': 7,
        'translation': 'Info',
      },
      'not_registered_text': {
        'id': 8,
        'translation':
            'You are not registered with TUDO. Please register first with TUDO and login',
      },
      'forgot_passowrd_text': {
        'id': 10,
        'translation': 'Forgot Password?',
      },
      'went_wrong_text': {
        'id': 11,
        'translation': 'Something went wrong!',
      },
      'login_text': {
        'id': 12,
        'translation': 'Log In',
      },
      'signup_text': {
        'id': 13,
        'translation': 'Sign Up',
      }
    },
    'signup': {
      'email': {
        'id': 1,
        'translation': 'Email',
      },
      'code': {
        'id': 2,
        'translation': 'Code',
      },
      'mobile': {
        'id': 3,
        'translation': 'Mobile Number',
      },
      'firstname': {
        'id': 4,
        'translation': 'First Name',
      },
      'lastname': {
        'id': 5,
        'translation': 'Last Name',
      },
      'password': {
        'id': 6,
        'translation': 'Password',
      },
      'termsandcondition': {
        'id': 7,
        'translation': 'I Agree Terms and Condition',
      },
      'clear': {
        'id': 8,
        'translation': 'Clear',
      },
      'signup': {
        'id': 9,
        'translation': 'Sign UP',
      },
      'backtologin': {
        'id': 10,
        'translation': 'Back to Login',
      }
    },
    'forgot': {
      'email': {
        'id': 1,
        'translation': 'Email',
      },
      'forgot': {
        'id': 2,
        'translation': 'Forgot Password',
      }
    },
    'cmrdashboard': {
      'deals': {
        'id': 1,
        'translation': 'Deals',
      },
      'eventer': {
        'id': 2,
        'translation': 'Even Ter',
      },
      'hanging': {
        'id': 3,
        'translation': 'Hanging',
      },
      'mynet': {
        'id': 4,
        'translation': 'My Net',
      },
      'nter': {
        'id': 5,
        'translation': 'Nter',
      },
      'payment': {
        'id': 6,
        'translation': 'Payment',
      },
      'report': {
        'id': 7,
        'translation': 'Report',
      },
      'schedule': {
        'id': 8,
        'translation': 'Schedule',
      },
    },
    'floatingbutton': {
      'addinhouse': {
        'id': 1,
        'translation': 'Add In-home',
      },
      'addevent': {
        'id': 2,
        'translation': 'Add Event',
      },
      'addtask': {
        'id': 3,
        'translation': 'Add Task',
      },
      'addondemand': {
        'id': 4,
        'translation': 'Add OnDemand',
      },
      'addwalkin': {
        'id': 5,
        'translation': 'Add Walk-in',
      },
    },
    'bspfloatingbutton': {
      'addbranch': {
        'id': 1,
        'translation': 'Add Branch',
      },
      'addemployee': {
        'id': 2,
        'translation': 'Add Employee',
      },
      'addinvoice': {
        'id': 3,
        'translation': 'Add Invoice',
      },
      'addfollowup': {
        'id': 4,
        'translation': 'Add Follow-Up appointment',
      },
    },
    'maindrawer': {
      'dashboard': {
        'id': 1,
        'translation': 'Dashboard',
      },
      'setting': {
        'id': 2,
        'translation': 'Setting',
      },
      'BusinessServiceProvider': {
        'id': 3,
        'translation': 'Become Business Service Provider',
      },
      'switchtobsp': {
        'id': 7,
        'translation': 'Switch to Business Provider',
      },
      'Notification': {
        'id': 4,
        'translation': 'Notification Preference',
      },
      'TUDO': {
        'id': 5,
        'translation': 'Refer TUDO',
      },
      'termsandcondition': {
        'id': 6,
        'translation': 'Legal Terms and Conditions',
      },
      'contactus': {
        'id': 7,
        'translation': 'Contact Us',
      },
      'signout': {
        'id': 8,
        'translation': 'Sign Out',
      },
    },
    'addemployee': {
      'branch': {
        'id': 1,
        'translation': 'Branch',
      },
      'dob': {
        'id': 3,
        'translation': 'Date of Birth',
      },
      'roleofmployee': {
        'id': 4,
        'translation': 'Role of Employee',
      },
      'rolename': {
        'id': 5,
        'translation': 'Role Name',
      },
      'vehicleid': {
        'id': 6,
        'translation': 'Vehicle Registration ID',
      },
      'service': {
        'id': 7,
        'translation': 'Service',
      },
      'employeetype': {
        'id': 8,
        'translation': 'Employee type',
      },
      'workshift': {
        'id': 9,
        'translation': 'Work Shift',
      },
      'payscale': {
        'id': 10,
        'translation': 'Pay Scale',
      },
      'annulabsencehours': {
        'id': 11,
        'translation': 'Annual Absence Hours',
      },
    },
    'addbranch': {
      'legalbusinessname': {
        'id': 1,
        'translation': 'Legal business name',
      },
      'branchphone': {
        'id': 2,
        'translation': 'Branch Phone #+1-(999)999-9999',
      },
      'licenseno': {
        'id': 2,
        'translation': 'Business License No',
      },
      'establishedyear': {
        'id': 2,
        'translation': 'Established Year',
      },
      'expirydate': {
        'id': 2,
        'translation': 'Licence Expiry date',
      },
      'profilepicture': {
        'id': 2,
        'translation': 'Business Profile Picture',
      },
      'licensepicture': {
        'id': 2,
        'translation': 'Business Licence Picture s',
      },
    },
    'bspSignup': {
      'legalbusinessname': {
        'id': 1,
        'translation': 'Business legal Name',
      },
      'alternatephone': {
        'id': 2,
        'translation': 'Business phone number',
      },
      'establishedyear': {
        'id': 3,
        'translation': 'Business establishment year',
      },
      'numberofemployees': {
        'id': 4,
        'translation': 'Number Of Employees',
      },
      'businesslegaladdress': {
        'id': 5,
        'translation': 'Business legal address',
      },
      'legalbusinesscheck': {
        'id': 6,
        'translation':
            'You must accept you are over 18 years of age to conitnue',
      },
      'errortext': {
        'id': 7,
        'translation': 'You must have to check this condition',
      },
      'title_business': {
        'id': 8,
        'translation': 'Business Signup',
      },
      'title_branch': {
        'id': 9,
        'translation': 'Business Branch',
      },
      'update': {
        'id': 10,
        'translation': 'Update',
      },
      'next': {
        'id': 11,
        'translation': 'Next',
      },
      'branch_legal_name': {
        'id': 12,
        'translation': 'Branch legal Name',
      },
      'code': {
        'id': 13,
        'translation': 'Code',
      },
      'branch_phone_number': {
        'id': 14,
        'translation': 'Branch phone number',
      },
      'branch_established_year': {
        'id': 15,
        'translation': 'Branch eastablished year',
      },
      'employee_in_branch': {
        'id': 16,
        'translation': 'Number of Employees in your branch',
      },
      'branch_legal_address': {
        'id': 17,
        'translation': 'Branch legal address',
      },
      'select_personal_identity_type': {
        'id': 18,
        'translation': 'Select Personal Identification type',
      },
      'select_branch_type': {
        'id': 19,
        'translation': 'Select Branch Business type',
      },
      'branch_type': {
        'id': 20,
        'translation': 'Branch type',
      },
      'business_type': {
        'id': 21,
        'translation': 'Business type',
      },
      'address_suggest_text': {
        'id': 22,
        'translation':
            'You can edit address to include Apt#, Plot#, Floor#, Suit#, etc.',
      },
      'branch_update_success': {
        'id': 23,
        'translation': 'Branch details updated successfully.',
      },
      'branch_established_years': {
        'id': 24,
        'translation': 'Branch established year',
      },
    },
    'bsplicensedsignupterms': {
      'selectcheck': {
        'id': 1,
        'translation':
            'Select checkbox(es) that are suitable and fits nature of your business. You may select all that are applicable',
      },
      'customervisitcheck': {
        'id': 2,
        'translation':
            'Customer visits my business facility for services rendered by my business (Walk-In Services)',
      },
      'customervisithelp': {
        'id': 3,
        'translation':
            'Help - Choose the above checkbox if customer(s) visit your business facility for the services your business renders. Example: Law firs, Doctor office, Barber shop, Bar&Restaurant, Hotels, Autoshop, etc.',
      },
      'businesscheck': {
        'id': 4,
        'translation':
            'My business personal visits customers places to render my business services (In-House Services)',
      },
      'businesscheckhelp': {
        'id': 5,
        'translation':
            'Help - Choose the above checkbox if your or your employees visits customers place for the services your business renders. Example: Home cleaning, Repair services, Handyman, Plumbing, Home Tutoring, Music classes, Nannys, Health aid, etc.',
      },
      'businessprovidecheck': {
        'id': 6,
        'translation':
            'My business provides location based On-Demand services to customers (On-Demand Services)',
      },
      'businessprovidehelp': {
        'id': 7,
        'translation':
            'Help - Choose the above checkbox if your business serves customers as On-Demand round the clock based services. Example: Taxi, Roadside assistance, Emergency repairs, Location based services, Food delivery, Ambulance, Public services, etc.',
      },
      'CustomerWalkIn': {
        'id': 8,
        'translation':
            'Customer visits my business facility for services rendered by my business (Walk-In Services)',
      },
      'CustomerInHome': {
        'id': 9,
        'translation':
            'My business personal visits customers places to render my business services (In-House Services)',
      },
      'BusinessOnDemand': {
        'id': 10,
        'translation':
            'My business provides location based On-Demand services to customers (On-Demand Services)',
      }
    },
    'unlicensedsignup': {
      'infromationislegitmate': {
        'id': 1,
        'translation':
            'Above entered Identity information is \nlegitimate and accurate to my knowledge'
      },
      'identificationtype1': {
        'id': 2,
        'translation': 'Select Personal Identification type 1'
      },
      'documentnumber1': {
        'id': 3,
        'translation': 'Personal ID number',
      },
      'issuingauthority1': {
        'id': 4,
        'translation': 'Photo Identification issuing authority'
      },
      'identificationtype2': {
        'id': 5,
        'translation': 'Select Personal Identification type 2'
      },
      'documentnumber2': {
        'id': 5,
        'translation': 'Business License No',
      },
      'issuingauthority2': {
        'id': 6,
        'translation': 'Photo Identification issuing authority'
      },
      'informationislegitmate': {
        'id': 7,
        'translation':
            'Above entered Identity information is legitimate and accurate to my knowledge'
      },
      'business_title': {
        'id': 9,
        'translation': 'Personal Identification Details',
      },
      'identity_document_number': {
        'id': 10,
        'translation': 'Identity Document Number',
      },
      'identification_expiry_date': {
        'id': 11,
        'translation': 'Identification expiry date',
      },
      'unlicensed_update_succ_text': {
        'id': 12,
        'translation': 'Branch unlicensed details updated successfully.',
      },
    },
    'licensedsignup': {
      'licenseissuingauthoritytype': {
        'id': 1,
        'translation': 'License Issuing Authority'
      },
      'businesslicenseno': {
        'id': 2,
        'translation': 'Business License Number',
      },
      'legalbusinesscheck': {
        'id': 3,
        'translation':
            'Above entered business license information is legitimate and accurate to my knowledge.',
      },
      'labeluploadlicenpicture': {
        'id': 4,
        'translation': 'Upload Front and Back images of your business license',
      },
      'licenseissuingauthority': {
        'id': 5,
        'translation': 'License Issuing Authority Name',
      },
      'branch_title': {
        'id': 6,
        'translation': 'Branch License Details',
      },
      'bsp_title': {
        'id': 7,
        'translation': 'Business License Details',
      },
      'document_required_text': {
        'id': 8,
        'translation': 'Document Required',
      },
      'document_required_desc': {
        'id': 9,
        'translation': 'You need to upload atleast 2 documents to proceed next'
      },
      // 'upload_branch_image': {'id': 10, 'translation': 'Upload Front and Back images of your business license'},
      'choose_an_image': {
        'id': 11,
        'translation': 'Choose an Image',
      },
      'gallery': {
        'id': 12,
        'translation': 'Gallery',
      },
      'camera': {
        'id': 13,
        'translation': 'Camera',
      },
      'licensed_update_succ_text': {
        'id': 14,
        'translation': 'Licensed details updated successfully.',
      },
      'branchlicenseno': {
        'id': 15,
        'translation': 'Branch License Number',
      },
      'expiry_date': {
        'id': 16,
        'translation': 'Business License Expiry Date',
      },
    },
    'businessprofile': {
      'agreeandaccept': {
        'id': 1,
        'translation':
            'I agree and accept to pay charges \nincurred inverifying my business background',
      },
      'uploadprofilepicture': {
        'id': 2,
        'translation': 'Upload business profile pictures:'
      },
      'accepttudo': {
        'id': 4,
        'translation': 'I Accept TUDO Terms and Condition',
      },
      'labeluploadlicenpicture': {
        'id': 5,
        'translation': 'Upload business license pictures',
      },
      'branch_title': {
        'id': 6,
        'translation': 'Branch Profile',
      },
      'business_title': {
        'id': 7,
        'translation': 'Business Provider Profile',
      },
      'branch_is': {
        'id': 8,
        'translation': 'Branch is:',
      },
      'business_is': {
        'id': 9,
        'translation': 'Business is:',
      },
      'write_branch_profile': {
        'id': 10,
        'translation': 'Write your branch profile',
      },
      'write_business_profile': {
        'id': 11,
        'translation': 'Write your business profile',
      },
      'other': {
        'id': 12,
        'translation': 'Other',
      },
      'explain_if_other_text': {
        'id': 13,
        'translation': 'Explain if other than insured and bonded',
      },
      'please_enter_branch_details': {
        'id': 14,
        'translation': 'Please enter branch profile details',
      },
      'please_enter_business_details': {
        'id': 15,
        'translation': 'Please enter business profile details',
      },
      'note': {
        'id': 16,
        'translation':
            'Note: Information entered here is visible to other TUDO users.',
      },
      'insured_text': {
        'id': 17,
        'translation': 'Insured for general liability',
      },
      'surety_text': {
        'id': 18,
        'translation': 'Surety bonded',
      },
      'branch_update_succ_text': {
        'id': 19,
        'translation': 'Branch profile details updated successfully."',
      },
      'upload_upto_5_text': {
        'id': 20,
        'translation': 'Upload up to 5 Business Profile Pictures',
      },
    },
    'select_service_page': {
      'title': {
        'id': 1,
        'translation': 'Select Services',
      },
      'no_service_type': {
        'id': 2,
        'translation': 'No Services available for this type.',
      },
      'please_select_one_service_desc': {
        'id': 3,
        'translation': 'Please select atleast one service type to proceed next',
      },
      'labeluploadlicenpicture': {
        'id': 4,
        'translation': 'Upload business license pictures',
      },
      'licenseissuingauthority': {
        'id': 5,
        'translation': 'License Issuing Authority',
      },
      'branch_title': {
        'id': 6,
        'translation': 'Branch Licensed Details',
      },
      'bsp_title': {
        'id': 7,
        'translation': 'Business Licensed Details',
      },
      'in_home_text': {
        'id': 8,
        'translation': 'In-Home Services',
      },
    },
    'language': {
      'language': {'id': 1, 'translation': 'Next'}
    },
    'reset': {
      'newpass': {'id': 1, 'translation': 'New Password'},
      'confirmpass': {'id': 1, 'translation': 'Confirm Password'}
    },
    'holidays': {
      "module": {"id": 1, 'translation': 'Business Holiday'},
      "createHoliday": {"id": 1, 'translation': 'Create Holiday'},
      "updateHoliday": {"id": 1, 'translation': 'Update Holiday'},
      "title": {"id": 1, 'translation': 'Title'},
      "discrption": {"id": 1, 'translation': 'Discription'},
      "dishint": {"id": 1, 'translation': 'Discription of Holiday'},
      "duration": {"id": 1, 'translation': 'Duration'},
      "todate": {"id": 1, 'translation': 'To Date'},
      "fromdate": {"id": 1, 'translation': 'From Date'},
      "totime": {"id": 1, 'translation': 'To Time'},
      "fromtime": {"id": 1, 'translation': 'From Time'},
      "ishalf": {"id": 1, 'translation': 'Is Half Day?'},
      "save": {"id": 1, 'translation': 'Save'},
      "update": {"id": 1, 'translation': 'Update'},
      "cancel": {"id": 1, 'translation': 'Cancel'},
      "delete": {"id": 1, 'translation': 'Delete'},
      "close": {"id": 1, 'translation': 'close'},
      "confDelete": {"id": 1, 'translation': 'Are you sure?'},
      "updateMessage": {"id": 1, 'translation': 'Successfully updated'},
      "createMessage": {"id": 1, 'translation': 'Successfully Created'},
      "deleteHoliday": {"id": 1, 'translation': 'Successfully Deleted'},
      "hitToDate": {"id": 1, 'translation': 'To Date'},
      "hitfromdate": {"id": 2, 'translation': 'From Date'},
      "hitToTime": {"id": 1, 'translation': 'To Time'},
      "hitFromTime": {"id": 1, 'translation': 'From Time'},
      "errTitle": {"id": 1, 'translation': 'Title is required.'},
      "errDis": {"id": 1, 'translation': 'Discription is required.'},
      "errToDate": {"id": 1, 'translation': 'To Date is required.'},
      "errToDate1": {"id": 1, 'translation': 'Invalid date range.'},
      "errFromDate": {"id": 1, 'translation': 'From Date is required.'},
      "errToTime": {"id": 1, 'translation': 'To Time is required.'},
      "errFromTime": {"id": 1, 'translation': 'From Time is required.'},
      "errToTime2": {"id": 1, 'translation': 'Invalid time range.'},
      "allholi": {"id": 1, 'translation': 'All Holiday'},
      "noholi": {"id": 1, 'translation': 'No holidays in this month'},
      "noholiist": {"id": 1, 'translation': 'No holidays avalible'},
    },
    'addwalkin': {
      'addwalkinservice': {
        'servicedate': {
          'id': 1,
          'translation': 'Service Date',
        },
        'flexibleme': {
          'id': 1,
          'translation': 'Flexible time (+/- 2 hours)',
        },
        'selectaddress': {
          'id': 1,
          'translation': 'Select Address',
        },
        'uploadpicture': {
          'id': 1,
          'translation': 'Upload Pictures',
        },
      }
    },
    'button': {
      'clear': {
        'id': 1,
        'translation': 'Clear',
      },
      'next': {
        'id': 2,
        'translation': 'Next',
      },
      'search': {
        'id': 3,
        'translation': 'Search',
      }
    },
    'shift_schedule': {
      "module": {"id": 1, 'translation': 'Business ShiftSchedule'},
      "begintime": {"id": 1, 'translation': 'Begin Time'},
      "endtime": {"id": 1, 'translation': 'End Time'},
      "title": {"id": 1, 'translation': 'Title'},
      "save": {"id": 1, 'translation': 'Save'},
      "namedis": {"id": 1, 'translation': 'Name or Discription'},
      "update": {"id": 1, 'translation': 'Update'},
      "offday": {"id": 1, 'translation': 'Offday'},
      "delete": {"id": 1, 'translation': 'Delete'},
      "default": {"id": 1, 'translation': 'Default'},
      "nameORdis": {"id": 1, 'translation': "Name or Discription"},
      "close": {"id": 1, 'translation': 'close'},
      "err1": {"id": 1, 'translation': 'Shift begin or end time is invalid'},
      "err2": {"id": 1, 'translation': 'Break begin or end time is invalid'},
      "err3": {"id": 1, 'translation': "Name or discription must required"},
      "err4": {
        "id": 1,
        'translation':
            "For the overlapping the other shift at least 2 hours of diffrent is required."
      },
      "err5": {"id": 1, 'translation': "Break time must be between shift time"},
      "err6": {"id": 1, 'translation': "Schedule successfully saved"},
      "err7": {"id": 1, 'translation': "Someting went wrong!"},
      "err8": {
        "id": 1,
        'translation': "Please enter the requied shift data to go ahead"
      },
      "err9": {
        "id": 1,
        'translation': "Do you want to set default schedule for business?"
      },
      "enter_shift_go_ahead": {
        "id": 1,
        'translation': "Enter shift time for go ahead"
      },
      "shift_time": {"id": 1, 'translation': "Shift Time"},
      "shift_breaks": {"id": 1, 'translation': "Shift Breaks"},
      "shift_txt": {"id": 1, 'translation': "Shift"},
      "business_hours_for": {"id": 1, 'translation': "Business hours for "},
    },
    "bsp_scheduled": {
      "module": {"id": 1, 'translation': 'Scheduled'},
      "filter_title": {"id": 1, 'translation': 'Filter By Status'},
    },
    "payments": {
      "module": {"id": 1, 'translation': 'Payments'},
      "filter_title": {"id": 1, 'translation': 'Filter By Status'},
    },
    "status": {
      "pending": {"id": 1, 'translation': 'Pending'},
      "waiting": {"id": 1, 'translation': 'Waiting'},
      "ignored": {"id": 1, 'translation': 'Ignored'},
      "rejected": {"id": 1, 'translation': 'Rejected'},
      "confirmed": {"id": 1, 'translation': 'Scheduled'},
      "started_heading": {"id": 1, 'translation': 'On-Way'},
      "started_working": {"id": 1, 'translation': 'On-Board'},
      "completed": {"id": 1, 'translation': 'Completed'},
      "invoiced": {"id": 1, 'translation': 'Invoiced'},
      "paid": {"id": 1, 'translation': 'Closed'},
      "closed": {"id": 1, 'translation': 'Closed'},
      "finalized": {"id": 1, 'translation': 'Completed'},
      "cancelled": {"id": 1, 'translation': 'Cancelled'},
      "scheduled": {"id": 1, 'translation': 'Scheduled'},
      "onway": {"id": 1, 'translation': 'On-Way'},
      "onboard": {"id": 1, 'translation': 'On-Board'},
      "invoice": {"id": 1, 'translation': 'Invoice'},
      "close": {"id": 1, 'translation': 'Close'},
    },
    "invoice": {
      "title": {"id": 1, 'translation': 'Invoice Detail'},
      "total_amt": {"id": 1, 'translation': 'Total Amount'},
      "send_invoice": {"id": 1, 'translation': 'Send Invoice'},
      "add_tax": {"id": 1, 'translation': 'Add Tax'},
      "add_tax_succ": {"id": 1, 'translation': 'Tax added successfully.'},
      "add_chrg": {"id": 1, 'translation': 'Add Charge'},
      "add_chrg_succ": {"id": 1, 'translation': 'Charge added successfully'},
      "add_discount": {"id": 1, 'translation': 'Add Discount'},
      "add_discount_succ": {
        "id": 1,
        'translation': 'Discount added successfully'
      },
      "add_comment": {"id": 1, 'translation': 'Add Comment'},
      "add_comment_succ": {
        "id": 1,
        'translation': 'Comment added successfully'
      },
      "send_conf": {
        "id": 1,
        'translation': 'Are you sure to send invoice to consumer?'
      },
      "send_tax_conf": {
        "id": 1,
        'translation':
            'You are obligated to collect Taxes and pay to the Government on the service(s) you render.  You DID NOT add taxes to this Invoice. Are you sure to proceed?'
      },
      "send_succ": {"id": 1, 'translation': 'Invoice Generated successfully'},
      "red_dis": {"id": 1, 'translation': "Request Adjustment"},
      "red_resone_title": {
        "id": 1,
        'translation': "Enter reasons for Adjustment Request"
      },
      "red_resone_label": {
        "id": 1,
        'translation':
            "Let your Service provide know the reasons for Adjustment Request..."
      },
      "red_resone_error": {
        "id": 1,
        'translation': "Please add reason for the request dispute"
      },
      "pay": {"id": 1, 'translation': 'Pay'},
      "submit": {"id": 1, 'translation': 'Submit'},
      "cancel": {"id": 1, 'translation': 'Cancel'},
      "tax_title": {"id": 1, 'translation': 'Enter Title'},
      "tax_error": {"id": 1, 'translation': 'Field should be required'},
      "tax_select_amt": {"id": 1, 'translation': "Select Amount"},
      "tax_in_per": {"id": 1, 'translation': "Amount :"},
      "tax_dis_title": {"id": 1, 'translation': "Enter Discription"},
      "tax_per": {"id": 1, 'translation': "Tax in %"},
      "max_allow": {"id": 1, 'translation': "Max Allow is"},
      "dis_title": {"id": 1, 'translation': 'Enter Title'},
      "dis_error": {"id": 1, 'translation': 'Field should be required'},
      "dis_select_amt": {"id": 1, 'translation': "Select Amount"},
      "dis_in_per": {"id": 1, 'translation': "Amount: "},
      "dis_dis_title": {"id": 1, 'translation': "Enter Discription"},
    },
    'add_card_details': {
      'card_number': {
        'id': 1,
        'translation': 'Card number',
      },
      'card_holder_name': {
        'id': 2,
        'translation': 'Card holder name',
      },
      'card_expiry_date': {
        'id': 3,
        'translation': 'Expiry date',
      },
      'card_cvv': {
        'id': 4,
        'translation': 'CVV',
      },
      'card_expiry_date_hint': {
        'id': 5,
        'translation': 'MM/YY',
      },
      'card_holder_name_hint': {
        'id': 6,
        'translation': 'Enter Card holder name',
      },
      'card_submit_button': {
        'id': 7,
        'translation': 'Add Card',
      },
      'add_card_details_title': {
        'id': 8,
        'translation': 'Add Card Details',
      },
      'add_card_success_dialog_desc': {
        'id': 9,
        'translation': 'Credit card added successfully',
      },
      'add_card_success_status': {
        'id': 10,
        'translation': 'Success',
      },
      'add_card_okay': {
        'id': 11,
        'translation': 'Okay',
      },
    },
    'select_payment_option_screen': {
      'select_payment_option_title': {
        'id': 1,
        'translation': 'Select payment option',
      },
      'payable_amount': {
        'id': 2,
        'translation': 'Total payable amount',
      },
      'choose_credit_card_title': {
        'id': 3,
        'translation': 'CHOOSE CREDIT CARD',
      },
      'add_new_debit_card_button_text': {
        'id': 4,
        'translation': '+  ADD new credit card',
      },
      'no_card_added': {
        'id': 5,
        'translation': 'No card added yet!',
      },
      'transaction_success_title': {
        'id': 6,
        'translation': 'Transaction success!',
      },
      'transaction_sucssess_desc': {
        'id': 7,
        'translation': 'Thank you so much for payment.',
      },
      'submit': {
        'id': 8,
        'translation': 'Submit',
      },
      'tudo_pass_hint': {
        'id': 9,
        'translation': 'Enter your TUDO Password',
      },
      'tudo_pass_label': {
        'id': 10,
        'translation': 'TUDO Password',
      },
      'select_card_error_desc': {
        'id': 11,
        'translation': 'Please select payment method',
      },
      'enter_tudo_pass_title': {
        'id': 12,
        'translation': 'Enter your password to proceed',
      },
      'delete_card_question': {
        'id': 13,
        'translation': 'Are you sure to delete this payment card?',
      },
      'credit_card_delete_success': {
        'id': 14,
        'translation': 'Credit card deleted successfully',
      },
      'default_method_ques': {
        'id': 15,
        'translation':
            'Are you sure to make this card as default payment option?',
      },
      'default_success': {
        'id': 16,
        'translation': 'Default Payment method created sucessfully.',
      },
      'long_press_desc': {
        'id': 17,
        'translation':
            '(* Note : Long press on card to make default payment method.)',
      },
      'expired_text': {
        'id': 18,
        'translation': 'Expired',
      },
      'pay_text': {
        'id': 19,
        'translation': 'Pay',
      },
      'my_wallet_text': {
        'id': 20,
        'translation': 'My Wallet',
      },
      'or_text': {
        'id': 21,
        'translation': 'OR',
      },
      'alternative_payments': {
        'id': 22,
        'translation': 'Alternative Payments',
      },
      'credt_card_number': {
        'id': 23,
        'translation': 'Credit Card Number',
      },
      'card_holder_name': {
        'id': 24,
        'translation': 'CARD HOLDER NAME',
      },
      'valid_thru': {
        'id': 25,
        'translation': 'VALID THRU',
      },
    },
    'create_braintree_account_screen': {
      'title': {
        'id': 1,
        'translation': 'Create braintree account',
      },
      'company_name': {
        'id': 2,
        'translation': 'Company Name',
      },
      'contact_number': {
        'id': 3,
        'translation': 'Contact number',
      },
      'contact_number_hint': {
        'id': 4,
        'translation': 'Enter contact number',
      },
      'cmr_terms_condition_text': {
        'id': 5,
        'translation':
            'Above entered Identity information is legitimate and accurate to my knowledgev',
      },
      'success_dialog_title': {
        'id': 6,
        'translation': 'Success',
      },
      'success_desc': {
        'id': 7,
        'translation': 'Braintree account created successfully',
      },
      'okay_text': {
        'id': 8,
        'translation': 'Okay',
      },
    },
    'tip_screen': {
      'tip_title': {
        'id': 1,
        'translation': 'Pay Invoice',
      },
      'tip_to_bsp': {
        'id': 2,
        'translation': 'Tip your Service Provider',
      },
      'tip_to_bsp_desc': {
        'id': 3,
        'translation': '50% of Tips goes to TUDO platform',
      },
      'donate_desc': {
        'id': 4,
        'translation': 'Donate to Charitable and Non-Profit organization',
      },
      'discount_per_label': {
        'id': 5,
        'translation': 'Discount percentage (%)',
      },
      'discount_per_hint': {
        'id': 6,
        'translation': 'Enter discount percentage (%)',
      },
      'invoiceAmt_text': {
        'id': 7,
        'translation': 'Invoice Amt.:',
      },
      'tipAmt_text': {
        'id': 8,
        'translation': 'Tip Amt.:',
      },
    },
    'payment_success_screen': {
      'payment_success_text': {
        'id': 1,
        'translation': 'Payment completed successfully!',
      },
      'sent_to_text': {
        'id': 2,
        'translation': 'Sent to',
      },
      'inovice_no_text': {
        'id': 3,
        'translation': 'Invoice No.',
      },
      'back_to_home_button': {
        'id': 4,
        'translation': 'Back to home',
      },
      'something_wrong_text': {
        'id': 5,
        'translation': 'Something went wrong',
      },
      'question_text': {
        'id': 6,
        'translation':
            'Do you like to give review and rating to this Business Service Provider?',
      },
      'yes_text': {
        'id': 7,
        'translation': 'Yes',
      },
      'no_text': {
        'id': 8,
        'translation': 'No',
      },
    },
    'bsp_review_screen': {
      'title': {
        'id': 1,
        'translation': 'Business Provider Review',
      },
      'rating_error': {
        'id': 2,
        'translation': 'Please give rating to Business Service Provider',
      },
      'post_positive': {
        'id': 3,
        'translation':
            'Post positive experience serving this Business Service Provider',
      },
      'post_improvements': {
        'id': 4,
        'translation':
            'Post Improvement experience serving this Business Service Provider',
      },
      'post_positive_desc': {
        'id': 5,
        'translation': 'Write what you liked the MOST',
      },
      'post_improvements_desc': {
        'id': 6,
        'translation': 'Write what you expect for next',
      },
      'submit_button': {
        'id': 7,
        'translation': 'Submit',
      },
      'thanks_text': {
        'id': 8,
        'translation': 'Thank you for review',
      },
      'close': {
        'id': 9,
        'translation': 'Close',
      },
      'field_required_text': {
        'id': 10,
        'translation': 'Field should be required',
      },
    },
    'create_hyperwallet_user': {
      'title': {
        'id': 1,
        'translation': 'Create Recievable Account',
      },
      'select_birth_country': {
        'id': 2,
        'translation': 'Select your birth country',
      },
      'country_of_birth': {
        'id': 3,
        'translation': 'Country of Birth',
      },
      'select_nationality': {
        'id': 4,
        'translation': 'Select your Nationality',
      },
      'country_of_nationality': {
        'id': 5,
        'translation': 'Country of Nationality',
      },
      'select_profile_type': {
        'id': 6,
        'translation': 'Select Profile type',
      },
      'enter_valid_date': {
        'id': 7,
        'translation': 'Please enter valid date',
      },
      'date_of_birth': {
        'id': 8,
        'translation': 'Date of Birth',
      },
      'business_name': {
        'id': 9,
        'translation': 'Business Name',
      },
      'select_gender': {
        'id': 10,
        'translation': 'Select Gender',
      },
      'select_business_type': {
        'id': 11,
        'translation': 'Select Business type',
      },
      'select_business_role': {
        'id': 12,
        'translation': 'Select Business Contact Role type',
      },
      'business_operating_name': {
        'id': 13,
        'translation': 'Business Operating Name',
      },
      'business_registration_id': {
        'id': 14,
        'translation': 'Business Registration ID',
      },
      'choose_address_from_map': {
        'id': 15,
        'translation': 'Choose Address from map',
      },
      'address_line1': {
        'id': 16,
        'translation': 'Address Line 1 (House No, Locality)',
      },
      'address_required': {
        'id': 17,
        'translation': 'Address',
      },
      'city': {
        'id': 18,
        'translation': 'City',
      },
      'city_required': {
        'id': 19,
        'translation': 'City Name',
      },
      'state': {
        'id': 20,
        'translation': 'State',
      },
      'state_required': {
        'id': 21,
        'translation': 'State Name',
      },
      'country': {
        'id': 22,
        'translation': 'Country',
      },
      'pincode': {
        'id': 23,
        'translation': 'Pincode',
      },
      'business_Address_details': {
        'id': 24,
        'translation': 'ADD BUSINESS ADDRESS DETAILS',
      },
      'business_address_line1': {
        'id': 25,
        'translation': 'Business Address Line 1 (House No, Locality)',
      },
      'business_address_required': {
        'id': 26,
        'translation': 'Business Address',
      },
      'business_city': {
        'id': 27,
        'translation': 'Business City',
      },
      'business_city_required': {
        'id': 28,
        'translation': 'Business City Name',
      },
      'business_state': {
        'id': 29,
        'translation': 'Business State',
      },
      'business_state_required': {
        'id': 30,
        'translation': 'Business State Name',
      },
      'business_country': {
        'id': 31,
        'translation': 'Business Country',
      },
      'business_pincode': {
        'id': 32,
        'translation': 'Business Pincode',
      },
      'checkbox_text': {
        'id': 33,
        'translation': 'Save above address information for Business',
      },
      'submit': {
        'id': 34,
        'translation': 'Submit',
      },
      'hyperwallet_success_text': {
        'id': 35,
        'translation': 'Hyperwallet account created successfully',
      },
      'paypal_email_id': {
        'id': 36,
        'translation': 'Paypal Email Id',
      },
      'paypal_email_id_hint': {
        'id': 37,
        'translation': 'Enter Paypal Email Id',
      },
      'email_desc': {
        'id': 38,
        'translation':
            '* We strongly recommend this email matches with your Paypal account id.',
      },
      'age_desc': {
        'id': 39,
        'translation': '* Age should be minimum 18 years.',
      },
      'contact_role_type': {
        'id': 40,
        'translation': "Business Contact Role type",
      },
      'business_type_text': {
        'id': 41,
        'translation': "Business type",
      },
      'birth_country_text': {
        'id': 42,
        'translation': 'Birth Country',
      },
      'nationality_country': {
        'id': 43,
        'translation': 'Nationality Country',
      },
      'address_type_text': {
        'id': 44,
        'translation': "Address type",
      },
      'gender_type_text': {
        'id': 45,
        'translation': "Gender type",
      },
    },
    'hyperwallet_recievable_screen': {
      'title': {
        'id': 1,
        'translation': 'Recievable Payment Methods',
      },
      'no_methods_text': {
        'id': 2,
        'translation': 'No Recievable payment methods added yet!',
      },
      'currency_type': {
        'id': 3,
        'translation': 'Currency Type : ',
      },
      'debit_cards': {
        'id': 4,
        'translation': 'Debit Cards',
      },
      'bank_accounts': {
        'id': 5,
        'translation': 'Bank Accounts',
      },
      'wire_accounts': {
        'id': 6,
        'translation': 'Wire Accounts',
      },
      'default_question_text': {
        'id': 7,
        'translation': 'Are you sure to change default transfer method?',
      },
      'default_transfer_success': {
        'id': 8,
        'translation': 'Default Transfer Payment method updated successfully.',
      },
    },
    'add_transfer_method_page_screen': {
      'title': {
        'id': 1,
        'translation': 'Add Transfer Payment Method',
      },
      'submit': {
        'id': 2,
        'translation': 'Submit',
      },
      'loading': {
        'id': 3,
        'translation': 'Loading.......',
      },
      'make_default': {
        'id': 4,
        'translation': 'Make Default Payment Method',
      },
      'country_currency_code': {
        'id': 5,
        'translation': 'Country Currency Code',
      },
      'choose_currency_code': {
        'id': 6,
        'translation': 'Choose currency code',
      },
      'select_country_currency': {
        'id': 7,
        'translation': 'Select Country Currency Code',
      },
      'transfer_method_type': {
        'id': 8,
        'translation': 'Transfer Method Type',
      },
      'select_transfer_method_type': {
        'id': 9,
        'translation': 'Select Transfer Method Type',
      },
      'payment_method': {
        'id': 10,
        'translation': 'Payment Transfer Method Added Successfully!',
      },
      'country_txt': {
        'id': 11,
        'translation': 'Country',
      },
      'choose_country_txt': {
        'id': 12,
        'translation': 'Choose Country',
      },
    },
    'business_setting_screen': {
      'block_hold_success': {
        'id': 1,
        'translation':
            'Block/Hold future appointments settings updated successfully.',
      },
      'auto_schedule_success': {
        'id': 2,
        'translation': 'Auto Schedule settings change successfully',
      },
      'bloc_hold_off_ques': {
        'id': 3,
        'translation': 'Are you sure to Block/Hold future Appointment?',
      },
      'bloc_hold_on_ques': {
        'id': 4,
        'translation': 'Do you want to restart future appointment?',
      },
      'auto_schedule_off_ques': {
        'id': 5,
        'translation': 'Do you want to off Auto schedule?',
      },
      'auto_schedule_on_ques': {
        'id': 6,
        'translation': 'Do you want to Auto schedule?',
      },
      'title': {
        'id': 7,
        'translation': 'Business Settings',
      },
      'business_profile_overview': {
        'id': 8,
        'translation': 'Business Profile Overview',
      },
      'business_country_specific': {
        'id': 9,
        'translation': 'Business Country Specific Settings',
      },
      'bloc_hold_future_appointments': {
        'id': 10,
        'translation': 'Block/Hold Future Appointment',
      },
      'auto_schedule': {
        'id': 11,
        'translation': 'Auto Schedule',
      },
      'business_service_radius': {
        'id': 12,
        'translation': 'Business Service Radius',
      },
      'business_service_duration': {
        'id': 13,
        'translation': 'Business Service Duration',
      },
      'my_wallet': {
        'id': 14,
        'translation': 'My Wallet',
      },
      'my_recievables': {
        'id': 15,
        'translation': 'My Recievables',
      },
      'business_sales_taxe_rate': {
        'id': 16,
        'translation': 'Business Sales Tax Rate',
      },
      'business_vehicles': {
        'id': 17,
        'translation': 'Business Vehicles',
      },
      'business_insaurance': {
        'id': 18,
        'translation': 'Business Insurance',
      },
      'employee_center': {
        'id': 19,
        'translation': 'Employee Center',
      },
      'business_branches': {
        'id': 20,
        'translation': 'Business Branches',
      },
      'license_and_usage_statistics': {
        'id': 21,
        'translation': 'License and Usage Statistics',
      },
      'invoice_allowance_limits': {
        'id': 22,
        'translation': 'Invoice allowance limits',
      },
      'business_rate_card': {
        'id': 23,
        'translation': 'Business rate card',
      },
      'business_shift_schedule': {
        'id': 24,
        'translation': 'Business Shift Schedule',
      },
      'business_holiday_calendar': {
        'id': 25,
        'translation': 'Business Holiday Calendars',
      },
      'business_service_estimates': {
        'id': 26,
        'translation': 'Business Service Estimates',
      },
      'license_usage_statistics': {
        'id': 27,
        'translation': 'License and usage statistics',
      },
      'subscription_text': {
        'id': 28,
        'translation': 'Subscriptions',
      },
    },
    'business_profile_overview_screen': {
      'title': {
        'id': 1,
        'translation': 'Business Overview',
      },
      'business_owner_details': {
        'id': 2,
        'translation': 'Business Owner Details:',
      },
      'user_name': {
        'id': 3,
        'translation': 'User Name',
      },
      'email': {
        'id': 4,
        'translation': 'Email',
      },
      'phone': {
        'id': 5,
        'translation': 'Phone',
      },
      'country': {
        'id': 6,
        'translation': 'Country',
      },
      'business_details': {
        'id': 7,
        'translation': 'Business details:',
      },
      'business_name': {
        'id': 8,
        'translation': 'Bussiness Name',
      },
      'business_phone': {
        'id': 9,
        'translation': 'Bussiness Phone',
      },
      'business_established_year': {
        'id': 10,
        'translation': 'Business Established Year',
      },
      'number_employees': {
        'id': 11,
        'translation': 'Number of Employees',
      },
      'business_license_details': {
        'id': 12,
        'translation': 'Business License Details:',
      },
      'business_license_number': {
        'id': 13,
        'translation': 'Business License Number',
      },
      'license_issuing_authority': {
        'id': 14,
        'translation': 'License Issuing Authority',
      },
      'uploaded_business_license_pictures': {
        'id': 15,
        'translation': 'Uploaded Business License Pictures',
      },
      'business_profile_details': {
        'id': 16,
        'translation': 'Business Profile Details:',
      },
      'business_profile_text': {
        'id': 17,
        'translation': 'Business Profile Text',
      },
      'uploaded_business_profile_pictures': {
        'id': 18,
        'translation': 'Uploaded Business Profile Pictures',
      },
      'business_Service_details': {
        'id': 19,
        'translation': 'Business Service Details:',
      },
      'selected_business_services': {
        'id': 20,
        'translation': 'Selected Business Services:',
      },
      'expiry_date': {
        'id': 21,
        'translation': 'License Expiry Date',
      },
      'business_type': {
        'id': 22,
        'translation': 'business type',
      },
      'branch_country': {
        'id': 23,
        'translation': 'Branch Country',
      },
    },
    'business_details_page_screen': {
      'business_profile_details': {
        'id': 1,
        'translation': 'Business Profile Details:',
      },
      'business_profile_text': {
        'id': 2,
        'translation': 'Business Profile Text',
      },
      'uploaded_business_profile_pictures': {
        'id': 3,
        'translation': 'Uploaded Business Profile Pictures',
      },
      'branch_title': {
        'id': 4,
        'translation': 'Branch Overview',
      },
      'business_title': {
        'id': 5,
        'translation': 'Business Overview',
      },
      'done': {
        'id': 6,
        'translation': 'Done',
      },
      'thanks_text_desc': {
        'id': 7,
        'translation':
            'Thank you for signing up as Business Service Provider (BSP), TUDO team review uploaded documents and activate your Business Service Provider account once satisfied. Up until then, your Business Profile will be in "Under Review" state. Stay tuned. Any questions? Reach us @1-800-888-TUDO',
      },
      'business_unlicense_details': {
        'id': 7,
        'translation': 'Business Unlicense Details:',
      },
      'personal_identity_1': {
        'id': 8,
        'translation': 'Personal Identity 1',
      },
      'personal_id_number': {
        'id': 9,
        'translation': 'Personal ID Number',
      },
      'expiry_date': {
        'id': 10,
        'translation': 'Expiry Date',
      },
      'personal_identity_type_1': {
        'id': 11,
        'translation': 'Personal Identification 1 type',
      },
      'uploaded_identification_image_1': {
        'id': 12,
        'translation': 'Uploaded Personal Identification 1 Pictures',
      },
      'personal_identity_2': {
        'id': 13,
        'translation': 'Personal Identity 2',
      },
      'personal_identity_type_2': {
        'id': 14,
        'translation': 'Personal Identification 2 type',
      },
      'uploaded_identification_image_2': {
        'id': 15,
        'translation': 'Uploaded Personal Identification 2 Pictures',
      },
      'selected_consents': {
        'id': 16,
        'translation': 'Selected Consents:',
      },
      'custom_issuing_authority_text': {
        'id': 17,
        'translation': 'Custom License Issuing Authority Name',
      },
      'selected_services': {
        'id': 18,
        'translation': 'Selected Services',
      },
    },
    'invoice_allowance_limits_screen': {
      'title': {
        'id': 1,
        'translation': 'Invoice Allowance Limits',
      },
      'update': {
        'id': 2,
        'translation': 'Update',
      },
      'invoice_allowance_confirm': {
        'id': 3,
        'translation': 'Are you sure want to change invoice allowance limits?',
      },
      'header_title': {
        'id': 4,
        'translation': 'Max allowed limits while creating \n Business Invoices',
      },
      'invoice_allowance_success': {
        'id': 5,
        'translation': 'Invoice allowance limits updated successfully.',
      },
      'default': {
        'id': 6,
        'translation': 'Default',
      },
      'max_allowed_tax': {
        'id': 7,
        'translation': 'Max allowed tax',
      },
      'Max_allowed_discount': {
        'id': 8,
        'translation': 'Max allowed discount',
      },
      'max_invoice_adjust_count': {
        'id': 9,
        'translation': 'Max Invoice Adjust count',
      },
    },
    'business_service_radius_screen': {
      'title': {
        'id': 1,
        'translation': 'Business Service Radius',
      },
      'update_confirm_text': {
        'id': 2,
        'translation':
            'Are you sure want to update business service radius settings?',
      },
      'update_success_text': {
        'id': 3,
        'translation': 'Business service radius settings updated successfully.',
      },
      'default_confirm_text': {
        'id': 4,
        'translation':
            'Are you sure want to make all service radius settings as previous?',
      },
      'same_radius_question_text': {
        'id': 5,
        'translation': 'Same Radius for all services?',
      },
    },
    'business_expected_duration_screen': {
      'title': {
        'id': 1,
        'translation': 'Business Service Duration',
      },
      'same_duration_question': {
        'id': 2,
        'translation': 'Same duration for all services?',
      },
      'home_service_label': {
        'id': 3,
        'translation': 'In-House Services',
      },
      'walk_in_label': {
        'id': 4,
        'translation': 'Walk-In Services',
      },
      'on_demand_label': {
        'id': 5,
        'translation': 'On-Demand Services',
      },
      'include_travel_text': {
        'id': 6,
        'translation': 'Include travel / commute time?',
      },
      'schedule_update_success_text': {
        'id': 7,
        'translation':
            'Business service duration settings updated successfully.',
      },
      'schedule_default_question_text': {
        'id': 8,
        'translation':
            'Are you sure want to make all service duration settings as previous?',
      },
      'schedule_update_question_text': {
        'id': 9,
        'translation':
            'Are you sure want to update business service duration settings?',
      },
      'something_wrong_server': {
        'id': 10,
        'translation': "Something went wrong on server",
      },
    },
    'business_rate_card_screen': {
      'title': {
        'id': 1,
        'translation': 'Business Rate Card',
      },
      'default_confirm_text': {
        'id': 2,
        'translation': "Do you want all rate card settings as previous?",
      },
      'update_confirm_text': {
        'id': 3,
        'translation': "Are you sure to update business rate card?",
      },
      'update_suceess_text': {
        'id': 4,
        'translation': "Business service rate card updated successfully.",
      },
      'fill_proper_text': {
        'id': 5,
        'translation': 'Please fill out all the values properly',
      },
      'common_price_text': {
        'id': 6,
        'translation': "Common price for all services?\n(In ",
      },
      'fixed_rate_label': {
        'id': 7,
        'translation': 'Fixed Rate',
      },
      'hourly_rate_label': {
        'id': 8,
        'translation': 'Hourly Rate',
      },
    },
    'business_sales_tax_rate_screen': {
      'title': {
        'id': 1,
        'translation': "Business Sales Tax",
      },
      'sales_tax_rate_label': {
        'id': 2,
        'translation': "Sales Tax rate ",
      },
      'tax_line_description': {
        'id': 3,
        'translation': "Business Tax line description",
      },
      'tax_rate_text': {
        'id': 4,
        'translation': "Tax Rate",
      },
      'common_tax_rate_question': {
        'id': 5,
        'translation': "Common Tax rate for all services?",
      },
      'tax_before_discount': {
        'id': 6,
        'translation': "Tax applied before any discounts?",
      },
      'select_tax_authority': {
        'id': 7,
        'translation': 'Select Tax Authority"',
      },
      'national_business_tax_text': {
        'id': 8,
        'translation': 'National Business Tax Identification number',
      },
      'regional_business_tax_text': {
        'id': 9,
        'translation': 'Regional Business Tax Identification number',
      },
      'update': {
        'id': 10,
        'translation': 'Update',
      },
      'please_fill_value_text': {
        'id': 11,
        'translation': 'Please fill out all the values properly.',
      },
      'businesss_sales_tax_rate_update_confirm': {
        'id': 12,
        'translation': 'Are you sure to update business sales tax rates?',
      },
      'businesss_sales_tax_rate_success': {
        'id': 13,
        'translation':
            "Business service sales tax rate card updated successfully.",
      },
      'businesss_sales_tax_rate_default_confirm': {
        'id': 14,
        'translation':
            '  "Do you want to sales tax rate settings as previous?"',
      },
      'tax_rate': {
        'id': 15,
        'translation': 'Tax Rate',
      },
      'should_be_in_range': {
        'id': 16,
        'translation': '(*Tax Rate should be between 0 to 99)',
      },
    },
    'business_service_estimates_screen': {
      'title': {
        'id': 1,
        'translation': 'Business Service Estimates',
      },
      'hrs_text': {
        'id': 2,
        'translation': "(/hrs)",
      },
      'save_confirm_text': {
        'id': 3,
        'translation': "Do you want to save business service estimates card?",
      },
      'save_suceess_text': {
        'id': 4,
        'translation':
            "Business service estimates settings saved successfully.",
      },
      'fill_proper_text': {
        'id': 5,
        'translation': 'Please fill out all the values properly',
      },
      'multiplier_factor_text': {
        'id': 6,
        'translation': "Multiplier Factor",
      },
      'note_text': {
        'id': 7,
        'translation': '* Note: Taxes are NOT included in the estimates',
      },
      'mins_text': {
        'id': 8,
        'translation': 'mins',
      },
      'travel_communicate_text': {
        'id': 9,
        'translation': 'Travel/ commute time included in estimates?',
      },
      'save': {
        'id': 9,
        'translation': 'Save',
      },
      'hr_text': {
        'id': 10,
        'translation': 'hrs',
      },
    },
    'personal_details_Screen': {
      'title': {
        'id': 1,
        'translation': 'Personal Data',
      },
      'phone_no': {
        'id': 2,
        'translation': "Phone Number",
      },
      'country_code': {
        'id': 3,
        'translation': "Country Code",
      },
      'code': {
        'id': 4,
        'translation': "code",
      },
      'address_mandatory': {
        'id': 5,
        'translation': 'Address (Mandatory)',
      },
      'address': {
        'id': 6,
        'translation': "Address",
      },
      'select_country': {
        'id': 7,
        'translation': 'Select Country',
      },
      'choose_country': {
        'id': 8,
        'translation': 'Choose Country',
      },
      'gender': {
        'id': 9,
        'translation': 'Gender',
      },
      'update_success_text': {
        'id': 9,
        'translation': 'Personal profile information updated successfully.',
      },
      'error': {
        'id': 10,
        'translation': 'Error',
      },
      'select_field_error_text': {
        'id': 11,
        'translation': 'Please Select All Required Items With Profile Picture',
      },
      'okay': {
        'id': 12,
        'translation': 'Okay',
      },
      'country': {
        'id': 13,
        'translation': 'Country',
      },
    },
    'personal_identification_screen': {
      'title': {
        'id': 1,
        'translation': 'Identification Detail',
      },
      'no_idnetification_available': {
        'id': 2,
        'translation': "No identification details available.",
      },
      'identification_type': {
        'id': 3,
        'translation': "Identification Type",
      },
      'identification_name': {
        'id': 4,
        'translation': "Identification Name",
      },
      'identification_number': {
        'id': 5,
        'translation': 'Identification Number',
      },
      'exp_date': {
        'id': 6,
        'translation': "Expiry Date",
      },
      'perosnal_identity_2': {
        'id': 7,
        'translation': 'Personal Identity 2',
      },
      'perosnal_identity_1': {
        'id': 8,
        'translation': 'Personal Identity 1',
      },
    },
    'add_personal_identification_screen': {
      'title': {
        'id': 1,
        'translation': 'Add Personal Identification',
      },
      'update_identification': {
        'id': 2,
        'translation': "Update Personal Identification",
      },
      'document_number_2': {
        'id': 3,
        'translation': "Personal Indentity Document 2 number",
      },
      'update_success': {
        'id': 4,
        'translation': "Personal identification updated successfully.",
      },
      'create_success': {
        'id': 5,
        'translation': 'Personal identification created successfully.',
      },
      'document_number_1': {
        'id': 6,
        'translation': "Personal Indentity Document 1 number",
      },
      'photo_identity_1_exp_date': {
        'id': 7,
        'translation': 'Photo identification 1 expiry date',
      },
      'photo_identity_2_exp_date': {
        'id': 8,
        'translation': 'Photo identification 2 expiry date',
      },
      'perosnal_identity_1_pictures': {
        'id': 9,
        'translation': 'Upload Personal Identity 1 Pictures',
      },
      'perosnal_identity_2_pictures': {
        'id': 10,
        'translation': 'Upload Personal Identity 2 Pictures',
      },
      'please_select_2_document': {
        'id': 11,
        'translation': 'Please select at least 2 document',
      },
      'terms': {
        'id': 12,
        'translation':
            'I have verified Identity information and accept its accuracy and legitimacy to my knowledge.',
      },
    },
    'education_and_qualification': {
      'title': {
        'id': 1,
        'translation': 'Education & Qualifications',
      },
      'no_education_text': {
        'id': 2,
        'translation': "No education and qualification added yet.",
      },
      'from': {
        'id': 3,
        'translation': "From:",
      },
      'to': {
        'id': 4,
        'translation': "To:",
      },
      'institution_country': {
        'id': 5,
        'translation': 'Institution, Country',
      },
    },
    'add_education_and_qualification': {
      'title': {
        'id': 1,
        'translation': 'Add Education & Qualification',
      },
      'add_success': {
        'id': 2,
        'translation': "Education and qualification added successfully.",
      },
      'update_success': {
        'id': 3,
        'translation': "Education and qualification updated successfully.",
      },
      'update_title': {
        'id': 4,
        'translation': "Update Education & Qualification",
      },
    },
    'education_and_qualification_info': {
      'title': {
        'id': 1,
        'translation': 'Education & Qualification Details',
      },
      'qualification_type': {
        'id': 2,
        'translation': "Qualification Type",
      },
      'qualification': {
        'id': 3,
        'translation': "Qualification",
      },
      'qualification_number': {
        'id': 4,
        'translation': "Qualification Number",
      },
      'qualification_field': {
        'id': 5,
        'translation': "Qualification Field",
      },
      'qualification_exp_date': {
        'id': 6,
        'translation': "Qualification Expiry Date",
      },
      'from_date': {
        'id': 7,
        'translation': "From Date",
      },
      'end_date': {
        'id': 8,
        'translation': "End Date",
      },
      'institution_name': {
        'id': 9,
        'translation': "Institution Name",
      },
      'country': {
        'id': 10,
        'translation': "Country",
      },
      'additional_details': {
        'id': 11,
        'translation': "Additional Details",
      },
      'uploaded_images_certificates': {
        'id': 12,
        'translation': "Uploaded Images/Certificates",
      },
      'delete_confirm': {
        'id': 13,
        'translation':
            "Are you sure to delete this education and qualification?",
      },
      'delete_success': {
        'id': 14,
        'translation': "Qualification deleted successfully.",
      },
    },
    'work_experience_list': {
      'title': {
        'id': 1,
        'translation': 'Work Experience',
      },
      'no_exp_added_text': {
        'id': 2,
        'translation': "No work experience added yet.",
      },
      'exp_type': {
        'id': 3,
        'translation': "Experience Type",
      },
      'emp_type': {
        'id': 4,
        'translation': "Employement Type",
      },
      'organization_name': {
        'id': 5,
        'translation': 'Organization Name',
      },
      'job_role': {
        'id': 6,
        'translation': "Job Role",
      },
      'contact_person_name': {
        'id': 7,
        'translation': "Contact Person Name",
      },
      'current': {
        'id': 8,
        'translation': "Current",
      },
      'from_date': {
        'id': 9,
        'translation': 'From Date:',
      },
      'end_date': {
        'id': 10,
        'translation': "End Date:",
      },
      'city_country': {
        'id': 11,
        'translation': "City, Country",
      },
    },
    'add_work_experience': {
      'title': {
        'id': 1,
        'translation': 'Add Work Experience',
      },
      'update_title': {
        'id': 2,
        'translation': "Update Work Experience",
      },
      'exp_added_success': {
        'id': 3,
        'translation': "Work Experience added successfully.",
      },
      'exp_update_success': {
        'id': 4,
        'translation': "Work Experience updated successfully.",
      },
    },
    'work_experience_info': {
      'title': {
        'id': 1,
        'translation': 'Work Experience Details',
      },
      'experience': {
        'id': 2,
        'translation': "Experience",
      },
      'refer_person_name': {
        'id': 3,
        'translation': 'Reference Person Name',
      },
      'refer_person_number': {
        'id': 4,
        'translation': "Reference Person Number",
      },
      'refer_person_email': {
        'id': 5,
        'translation': 'Reference Person Email',
      },
    },
    'insurance_list': {
      'title': {
        'id': 1,
        'translation': 'My Insurances',
      },
      'no_insurance_added_text': {
        'id': 2,
        'translation': "No Insurance added yet.",
      },
      'agent_name': {
        'id': 3,
        'translation': "Agent Name",
      },
    },
    'add_insurance': {
      'title': {
        'id': 1,
        'translation': 'Add Insurance',
      },
      'update_title': {
        'id': 2,
        'translation': "Update Insurance",
      },
      'insurance_added_success': {
        'id': 3,
        'translation': "Insurance added successfully.",
      },
      'insurance_update_success': {
        'id': 4,
        'translation': "Insurance updated successfully.",
      },
    },
    'insurance_info': {
      'title': {
        'id': 1,
        'translation': 'Insurance Details',
      },
      'insurance_policy_type': {
        'id': 2,
        'translation': "Insurance Policy Type",
      },
      'policy_name': {
        'id': 3,
        'translation': "Policy Name",
      },
      'insurance_company': {
        'id': 4,
        'translation': "Insurance Company Name",
      },
      'insurance_policy_number': {
        'id': 5,
        'translation': "Insurance Policy Number",
      },
      'from_date': {
        'id': 7,
        'translation': "From Date",
      },
      'end_date': {
        'id': 8,
        'translation': "End Date",
      },
      'policy_value': {
        'id': 9,
        'translation': "Policy Value/ Limit",
      },
      'agent_name': {
        'id': 10,
        'translation': "Agent Name",
      },
      'agent_mobile_number': {
        'id': 11,
        'translation': "Agent Mobile Number",
      },
      'agent_email_id': {
        'id': 12,
        'translation': "Agent Email Id",
      },
      'additional_information': {
        'id': 13,
        'translation': "Additional Information",
      },
      'uploaded_images_policies': {
        'id': 14,
        'translation': "Uploaded Images/Policies",
      },
      'delete_insurance_confirm': {
        'id': 15,
        'translation': "Are you sure to delete this insurance?",
      },
      'delete_inusrance_success_text': {
        'id': 16,
        'translation': "Insurance deleted successfully.",
      },
    },
    'vehicle_list': {
      'title': {
        'id': 1,
        'translation': 'My Vehicles',
      },
      'no_vehicles_added_text': {
        'id': 2,
        'translation': "No vehicle details added yet.",
      },
    },
    'add_vehicles': {
      'title': {
        'id': 1,
        'translation': 'Add Vehicles',
      },
      'update_title': {
        'id': 2,
        'translation': "Update Vehicles",
      },
      'vehicles_added_success': {
        'id': 3,
        'translation': "Vehicles added successfully.",
      },
      'vehicles_update_success': {
        'id': 4,
        'translation': "Vehicle details updated successfully.",
      },
    },
    'vehicle_info': {
      'title': {
        'id': 1,
        'translation': 'Vehicle Details',
      },
      'vehicle_model': {
        'id': 2,
        'translation': "Vehicle Model",
      },
      'vehicle_make': {
        'id': 3,
        'translation': "Vehicle Make",
      },
      'vehicle_type_name': {
        'id': 4,
        'translation': "Vehicle Type Name",
      },
      'vehicle_type_id': {
        'id': 5,
        'translation': "Vehicle Type Id",
      },
      'vehicle_identification_number': {
        'id': 7,
        'translation': "Vehicle Identification Number",
      },
      'vehicle_register_number': {
        'id': 8,
        'translation': "Vehicle Registration Number",
      },
      'vehicle_paint_color': {
        'id': 9,
        'translation': "Vehicle Body Paint Color",
      },
      'vehicle_type': {
        'id': 10,
        'translation': "Vehicle Type",
      },
      'vehicle_modal_year': {
        'id': 11,
        'translation': "Vehicle Modal Year",
      },
      'permit1': {
        'id': 12,
        'translation': "Permit 1",
      },
      'expiry_date': {
        'id': 13,
        'translation': "Expiry Date",
      },
      'permit2': {
        'id': 14,
        'translation': "Permit 2",
      },
      'permit3': {
        'id': 15,
        'translation': "Permit 3",
      },
      'additional_information': {
        'id': 16,
        'translation': "Additional Details",
      },
      'uploaded_images': {
        'id': 17,
        'translation': "Uploaded Images",
      },
      'delete_confirm': {
        'id': 18,
        'translation': "Are you sure to delete this vehicle information?",
      },
      'delete_success_text': {
        'id': 19,
        'translation': "Vehicle deleted successfully.",
      },
    },
    'cmr_drawer': {
      'logout_confirm': {
        'id': 1,
        'translation': 'Are you sure you want to Logout?',
      },
      'yes': {
        'id': 2,
        'translation': "Yes",
      },
      'no': {
        'id': 3,
        'translation': "No",
      },
      'logout_failed': {
        'id': 4,
        'translation': "User Logout Failed!",
      },
      'error': {
        'id': 5,
        'translation': 'Error',
      },
      'tudo_text': {
        'id': 6,
        'translation': "TUDO",
      },
      'about_tudo': {
        'id': 7,
        'translation': "About TUDO",
      },
      'contact_tudo': {
        'id': 8,
        'translation': "Contact TUDO",
      },
      'refer_tudo': {
        'id': 9,
        'translation': 'Refer TUDO',
      },
      'tudo_legal': {
        'id': 10,
        'translation': "TUDO Legal Terms and Conditions",
      },
      'tudo_help': {
        'id': 11,
        'translation': "TUDO Application help",
      },
      'my_profile': {
        'id': 12,
        'translation': "My Profile",
      },
      'my_indentification': {
        'id': 13,
        'translation': "My Identification",
      },
      'education_quali': {
        'id': 14,
        'translation': "My Education & Qualification",
      },
      'work_experience': {
        'id': 15,
        'translation': 'My Work Experience',
      },
      'my_address': {
        'id': 16,
        'translation': "My Address",
      },
      'my_wallet': {
        'id': 17,
        'translation': "My Wallet",
      },
      'my_insurance': {
        'id': 18,
        'translation': "My Insurance",
      },
      'my_vehicles': {
        'id': 19,
        'translation': 'My Vehicles',
      },
      'my_family': {
        'id': 20,
        'translation': "My Family",
      },
      'themes': {
        'id': 21,
        'translation': "Themes",
      },
      'my_prefernces': {
        'id': 22,
        'translation': "My Preferences",
      },
      'logout_text': {
        'id': 23,
        'translation': "Logout",
      },
      'provide_feedback': {
        'id': 21,
        'translation': "Provide feedback on TUDO",
      },
      'terms_of_use': {
        'id': 22,
        'translation': "TUDO Terms of use",
      },
      'privacy_notice': {
        'id': 23,
        'translation': "TUDO Privacy notice",
      },
      'mail_subject': {
        'id': 24,
        'translation': "TUDO User",
      },
      'mail_body': {
        'id': 25,
        'translation': "Hello TUDO Support team.",
      },
      'demo_txt': {
        'id': 26,
        'translation': "Demo",
      },
    },
    'identity_screen': {
      'identity_number': {
        'id': 1,
        'translation': "Identity Document Number",
      },
      'identity_name': {
        'id': 2,
        'translation': "Name of issuing authority",
      },
      'identity_exp_Date': {
        'id': 3,
        'translation': "Identification expiry date",
      },
      'enter_valid_date': {
        'id': 4,
        'translation': 'Please enter valid date',
      },
      'select_identity_type': {
        'id': 5,
        'translation': "Select Personal Identification type",
      },
      'knodledge_desc': {
        'id': 6,
        'translation':
            'I have verified Identity information and accept its accuracy and legitimacy to my knowledge.',
      },
      'at_least_2_docs': {
        'id': 7,
        'translation': 'Please select at least 2 document',
      },
      'upload_pictures': {
        'id': 8,
        'translation': 'Upload document pictures',
      },
    },
    'experience_screen': {
      'work_exp_type': {
        'id': 1,
        'translation': "Work experience type",
      },
      'work_exp': {
        'id': 2,
        'translation': "Work experience",
      },
      'enter_name': {
        'id': 3,
        'translation': "Enter Name of Organization(If applicable)",
      },
      'name_of_oraga': {
        'id': 4,
        'translation': 'Name of Organization(If applicable)',
      },
      'enter_city': {
        'id': 5,
        'translation': "Enter City of your Work with the above Organization",
      },
      'city_of_organization': {
        'id': 6,
        'translation': 'City of organization',
      },
      'enter_role_with_organization': {
        'id': 7,
        'translation': 'Enter your Role with the above Organization',
      },
      'role_with_organization': {
        'id': 8,
        'translation': 'Job role with the organization',
      },
      'currently_question': {
        'id': 9,
        'translation': 'I am currently working with this Organization?',
      },
      'begin_date': {
        'id': 10,
        'translation': 'Begin Date',
      },
      'end_date': {
        'id': 11,
        'translation': 'End Date',
      },
      'person_name': {
        'id': 12,
        'translation': 'Contact person name from this Organizations',
      },
      'reference_person_name': {
        'id': 13,
        'translation': 'Reference/ Contact Person Name',
      },
      'refer_person_email': {
        'id': 14,
        'translation':
            'Reference/ Contact person Email Id from this organization',
      },
      'contact_per_email': {
        'id': 15,
        'translation': 'Contact person email id from this Organization',
      },
      'elaborate_job': {
        'id': 16,
        'translation': 'Elaboarte your Job Duties',
      },
      'additional_info': {
        'id': 17,
        'translation':
            'Additional Information about a Success or Failure story',
      },
      'country_is_required': {
        'id': 18,
        'translation': 'Country is required',
      },
      'country': {
        'id': 19,
        'translation': 'Country',
      },
      'code': {
        'id': 20,
        'translation': 'code',
      },
      'country_code': {
        'id': 21,
        'translation': 'Country Code',
      },
      'contact_person_number': {
        'id': 22,
        'translation': 'Contact person phone number from this Organization',
      },
      'refer_person_phone_number': {
        'id': 23,
        'translation': 'Reference/Contact Person Phone Number',
      },
    },
    'conditions_policies': {
      'i_have_read': {
        'id': 1,
        'translation': "I have read and agree to",
      },
      'tudo_terms_use': {
        'id': 2,
        'translation': "TUDO Terms of Use",
      },
      'and_text': {
        'id': 3,
        'translation': "and",
      },
      'privacy_policies': {
        'id': 4,
        'translation': 'Privacy Notice',
      },
      'must_accept_terms_to_continue': {
        'id': 5,
        'translation': "You must accept terms and conditions to continue",
      },
      'brainree_terms_of_use': {
        'id': 6,
        'translation': 'Braintree Terms of Use',
      },
      'agree_back_verification': {
        'id': 7,
        'translation':
            'I agree and accept to pay any charges incurred in my background verification',
      },
      'hyperwallet_terms': {
        'id': 8,
        'translation': 'Hyperwallet Terms of Use',
      },
      'licensed_terms_text': {
        'id': 9,
        'translation':
            'I certify that the information entered is legitimate and valid to my knowledge',
      },
      'unlicensed_terms_text': {
        'id': 10,
        'translation':
            'I certify that the above information belongs to me and legitimate',
      },
      'owner_business_text': {
        'id': 11,
        'translation':
            'I am the legal owner of this business and over 18 years of age',
      },
    },
    'sign_up_screen': {
      'enter_first_name': {
        'id': 1,
        'translation': "Enter your First name",
      },
      'first_name': {
        'id': 2,
        'translation': "First Name",
      },
      'enter_last_name': {
        'id': 3,
        'translation': "Enter your Last name",
      },
      'last_name': {
        'id': 4,
        'translation': 'Last Name',
      },
      'enter_your_password': {
        'id': 5,
        'translation': "Enter your password",
      },
      'error_text': {
        'id': 6,
        'translation': 'Error',
      },
      'okay_text': {
        'id': 7,
        'translation': "Okay",
      },
      'signup_failed': {
        'id': 8,
        'translation': 'Signup failed',
      },
      'tudo_sign_up': {
        'id': 9,
        'translation': 'TUDO Sign up',
      },
      'back_to_login': {
        'id': 10,
        'translation': "Back to Login?",
      },
    },
    'otp_verify_screen': {
      'time_is_expired': {
        'id': 1,
        'translation': "Time is Expired",
      },
      'your_time_desc': {
        'id': 2,
        'translation':
            "Your time is expired please press reset button to get a new OTP",
      },
      'resend_the_otp': {
        'id': 3,
        'translation': "Resend the otp",
      },
      'wait_text': {
        'id': 4,
        'translation': 'Wait',
      },
      'enter_otp': {
        'id': 5,
        'translation': "Enter OTP",
      },
      'emailed_otp_text': {
        'id': 6,
        'translation':
            'We have Texted and/or Emailed OTP (One Time Pin) to your registered cell phone and/ or email account. Please check and enter OTP below to activate your TUDO account.',
      },
      'success_text': {
        'id': 7,
        'translation': "Success!",
      },
      'pass_change_success_text': {
        'id': 8,
        'translation': 'Your Password has been successfully changed!',
      },
      'okay_text': {
        'id': 9,
        'translation': 'Okay',
      },
      'fill_cell_text': {
        'id': 10,
        'translation': "*Please fill up all the cells properly",
      },
      'didnt_get_code_text': {
        'id': 11,
        'translation': "Didn't receive the code? Resend",
      },
      'aye_text': {
        'id': 12,
        'translation': 'Aye!!',
      },
      'verify_text': {
        'id': 13,
        'translation': 'VERIFY',
      },
      'clear_text': {
        'id': 14,
        'translation': "Clear",
      },
      'logout_failed': {
        'id': 15,
        'translation': 'User Logout Failed!',
      },
      'error_text': {
        'id': 16,
        'translation': 'Error',
      },
    },
    'language_screen': {
      'select_language': {
        'id': 1,
        'translation': "Select Language",
      },
      'next_text': {
        'id': 2,
        'translation': "Next",
      },
      'select_calendar': {
        'id': 3,
        'translation': "Select Calendar",
      },
    },
    'insurance_screen': {
      'policy_periods': {
        'id': 1,
        'translation': "Policy validity periods",
      },
      'atleast_2_document': {
        'id': 2,
        'translation': "Please select at least 2 document",
      },
      'upload_doc_text': {
        'id': 3,
        'translation': "Upload document pictures",
      },
      'insurance_policy_cat_text': {
        'id': 4,
        'translation': 'Insurance policy category',
      },
      'valid_date_txt': {
        'id': 5,
        'translation': "Please enter valid date",
      },
      'start_date_text': {
        'id': 6,
        'translation': 'Start Date',
      },
      'end_date_text': {
        'id': 7,
        'translation': 'End Date',
      },
      'name_of_policy_text': {
        'id': 8,
        'translation': 'Name of your insuarance policy',
      },
      'additional_info_text': {
        'id': 9,
        'translation': 'Additional Information',
      },
      'policy_value_limit_text': {
        'id': 10,
        'translation': 'Policy Value/ Limit',
      },
      'agent_email_id_text': {
        'id': 11,
        'translation': 'Insurance agent email id',
      },
      'insurance_company_name_text': {
        'id': 12,
        'translation': 'Insurance company name',
      },
      'agent_name_text': {
        'id': 13,
        'translation': 'Insurance agent name',
      },
      'policy_number_text': {
        'id': 14,
        'translation': 'Insurance policy number',
      },
      'code_text': {
        'id': 15,
        'translation': 'code',
      },
      'country_code_text': {
        'id': 16,
        'translation': 'Country Code',
      },
      'agent_phone_number_text': {
        'id': 17,
        'translation': 'Insaurnace agent phone number',
      },
    },
    'personal_screen': {
      'please_select_image': {
        'id': 1,
        'translation': "Please select image",
      },
      'error_while_pick_image_txt': {
        'id': 2,
        'translation': "Error while Picking Image",
      },
      'please_choose_image_txt': {
        'id': 3,
        'translation': "Please choose Image",
      },
      'gallery_txt': {
        'id': 4,
        'translation': "Gallery",
      },
      'camera_txt': {
        'id': 5,
        'translation': "Camera",
      },
      'please_select_gender': {
        'id': 6,
        'translation': "Please select geneder",
      },
      'gender_txt': {
        'id': 7,
        'translation': "Gender",
      },
    },
    'qualification_screen': {
      'additional_activites': {
        'id': 1,
        'translation': "Additional activities or participation",
      },
      'additional_during_study_text': {
        'id': 2,
        'translation':
            "Additional activities or participation etc. during the study",
      },
      'qualification_field_study': {
        'id': 3,
        'translation': "Qualification field or study",
      },
      'highest_degree_qualification': {
        'id': 4,
        'translation': "Highest Degree or Qualification?",
      },
      'enter_instituation_name': {
        'id': 5,
        'translation': "Enter Institution Name",
      },
      'name_of_institution': {
        'id': 6,
        'translation': "Name of Inistitution",
      },
      'qualification_certificate_number': {
        'id': 7,
        'translation': "Enter Qualification or Certification number",
      },
      'certificate_number': {
        'id': 8,
        'translation': "Qualification or Certificate Number",
      },
      'qualification_type': {
        'id': 9,
        'translation': "Qualification type",
      },
      'qualification_required_txt': {
        'id': 10,
        'translation': "Qualification is required",
      },
      'qualification_txt': {
        'id': 11,
        'translation': "Qualification",
      },
      'select_qualification': {
        'id': 12,
        'translation': "Select Qualification",
      },
    },
    'vehicle_screen': {
      'body_paint_color': {
        'id': 1,
        'translation': "Vehicle body paint color",
      },
      'registration_plate_number': {
        'id': 2,
        'translation': "Vehicle registration/plate number",
      },
      'vehicle_make': {
        'id': 3,
        'translation': "Vehicle Make",
      },
      'vehicle_model': {
        'id': 4,
        'translation': "Vehicle Modal",
      },
      'vehicle_identity_number': {
        'id': 5,
        'translation': "Vehicle Identification Number",
      },
      'vehicle_type_text': {
        'id': 6,
        'translation': "Vehicle Type",
      },
      'model_year_text': {
        'id': 7,
        'translation': "Vehicle Model Year",
      },
      'vehicle_permit_1': {
        'id': 8,
        'translation': "Vehicle Permit 1",
      },
      'vehicle_permit_2': {
        'id': 9,
        'translation': "Vehicle Permit 2",
      },
      'vehicle_permit_3': {
        'id': 10,
        'translation': "Vehicle Permit 3",
      },
      'additional_info_txt': {
        'id': 11,
        'translation': "Additional Information",
      },
    },
    'calendar_module': {
      'select_calendar_text': {
        'id': 1,
        'translation': "Select your calendar",
      },
      'tudo_appointment': {
        'id': 2,
        'translation': "TUDO appointment",
      },
      'for_text': {
        'id': 3,
        'translation': "for",
      },
      'service_text': {
        'id': 4,
        'translation': "service",
      },
      'save': {
        'id': 5,
        'translation': "Save",
      },
    },
    'invoice_module': {
      'add_charge': {
        'id': 1,
        'translation': "Add Charge",
      },
      'enter_title': {
        'id': 2,
        'translation': "Enter Title",
      },
      'field_should_required': {
        'id': 3,
        'translation': "Field should be required",
      },
      'enter_quantity': {
        'id': 4,
        'translation': "Enter Quantity",
      },
      'should_be_decimal': {
        'id': 5,
        'translation': "Quantity should be in decimal number",
      },
      'quan_greater_text': {
        'id': 6,
        'translation': "Quantity should be graterthen 0",
      },
      'unit_price_text': {
        'id': 7,
        'translation': "Enter Unit Price",
      },
      'charge_in_number': {
        'id': 8,
        'translation': "Charge should be in number",
      },
      'charge_greater_text': {
        'id': 9,
        'translation': "Charge should be graterthen 0",
      },
      'enter_desc_text': {
        'id': 10,
        'translation': "Enter Discription",
      },
      'applicable_tax': {
        'id': 11,
        'translation': "Applicable for Tax",
      },
      'applicable_discount': {
        'id': 12,
        'translation': "Applicable for Discount",
      },
      'add_comment_text': {
        'id': 13,
        'translation': "Add Comment",
      },
      'write_billing_text': {
        'id': 14,
        'translation': "Write Billing/ Invoicing comments",
      },
      'reason_Additional_charge': {
        'id': 15,
        'translation':
            "Write reasons for addintional charges or Service details etc",
      },
      'type_comments': {
        'id': 16,
        'translation': "Type your comments",
      },
      'invoice_details': {
        'id': 17,
        'translation': "Invoice Detail",
      },
      'invoice_generated_success_text': {
        'id': 18,
        'translation': "Invoice generated successfully",
      },
      'please_give_rating_text': {
        'id': 18,
        'translation': "Please give rating to consumer",
      },
      'post_positive_text': {
        'id': 19,
        'translation': "Post positive experience serving this Consumer",
      },
      'what_you_like_most_text': {
        'id': 20,
        'translation': "Write what you liked the MOST",
      },
      'invoice_txt': {
        'id': 21,
        'translation': "Invoice#",
      },
      'charges_text': {
        'id': 22,
        'translation': "Charges",
      },
      'discounts': {
        'id': 23,
        'translation': "Discounts",
      },
      'taxes': {
        'id': 24,
        'translation': "Taxes",
      },
      'other': {
        'id': 25,
        'translation': "Other",
      },
      'total': {
        'id': 26,
        'translation': "Total",
      },
      'do_you_remove': {
        'id': 27,
        'translation': "Do you want to remove?",
      },
      'qty_txt': {
        'id': 28,
        'translation': "Qty.",
      },
      'unit_price': {
        'id': 29,
        'translation': "Unit Price:",
      },
      'app_title': {
        'id': 30,
        'translation': "Appt. Title",
      },
      'app_date': {
        'id': 31,
        'translation': "Appt. Date",
      },
      'payment_type_text': {
        'id': 32,
        'translation': "Payment Type",
      },
      'from_txt': {
        'id': 33,
        'translation': "From",
      },
      'invoice_date': {
        'id': 34,
        'translation': "Invoice Date",
      },
      'agent_txt': {
        'id': 35,
        'translation': "Agent",
      },
      'bill_to_text': {
        'id': 36,
        'translation': "Bill To",
      },
      'comments_txt': {
        'id': 37,
        'translation': "Commets",
      },
    },
    'leads_prospects_module': {
      'leads_text': {
        'id': 1,
        'translation': 'Leads',
      },
      'prospects_text': {
        'id': 2,
        'translation': 'Prospects',
      },
    },
    'branch_list_screen': {
      'title': {
        'id': 1,
        'translation': 'Branch List',
      },
      'inactive_text': {
        'id': 2,
        'translation': 'Inactivate',
      },
      'active_text': {
        'id': 3,
        'translation': 'Active',
      },
      'employees_text': {
        'id': 4,
        'translation': 'Employees',
      },
      'address_text': {
        'id': 5,
        'translation': 'Address',
      },
      'delete_text': {
        'id': 6,
        'translation': 'Delete',
      },
      'error_text': {
        'id': 7,
        'translation': 'Error',
      },
    },
    'bsp_signup_licensed_terms_page': {
      'title': {
        'id': 1,
        'translation': 'Select Services',
      },
      'atleast_one_service_text': {
        'id': 2,
        'translation': 'Please select atleast one service type to proceed next',
      },
      'deactive_confirm_text': {
        'id': 3,
        'translation':
            'Are you sure to diactive this service from your branch?',
      },
      'deactive_success_text': {
        'id': 4,
        'translation': 'Branch deactivated successfully.',
      },
      'add_service_confirm_text': {
        'id': 5,
        'translation': 'Are you sure to add this service to your branch?',
      },
      'service_added_success_text': {
        'id': 6,
        'translation': 'Branch service added successfully.',
      },
    },
    'employee_center_module': {
      'error_while_picking_text': {
        'id': 1,
        'translation': "Error while Picking Image",
      },
      'please_choose_img_text': {
        'id': 2,
        'translation': "Please choose Image",
      },
      'choose_an_image': {
        'id': 3,
        'translation': "Choose an Image",
      },
      'enter_first_name_text': {
        'id': 4,
        'translation': "Enter your First name",
      },
      'employee_first_name': {
        'id': 5,
        'translation': "Employee First Name",
      },
      'employee_last_name': {
        'id': 6,
        'translation': "Employee Last Name",
      },
      'employee_address_mandatory': {
        'id': 7,
        'translation': "Employee Address (Mandatory)",
      },
      'employee_address_text': {
        'id': 8,
        'translation': "Employee Address",
      },
      'employee_demographic_text': {
        'id': 9,
        'translation': "Employee Demographic info",
      },
      'please_select_all_required_fields': {
        'id': 10,
        'translation': "Please Select All Required Items With Profile Picture",
      },
      'employee_identification_title': {
        'id': 11,
        'translation': "Employee Identification",
      },
      'employee_list_title': {
        'id': 12,
        'translation': "Employee List",
      },
      'hey_there_text': {
        'id': 13,
        'translation': "Hey there !",
      },
      'scan_tudo_text': {
        'id': 14,
        'translation': "Scan Tudo QR code",
      },
      'take_new_employee': {
        'id': 15,
        'translation': "Take New Employee",
      },
      'employee_idnetity': {
        'id': 16,
        'translation': "Employee Identification",
      },
    },
    'job_listing_screen': {
      'spend_100_text': {
        'id': 1,
        'translation': 'Spend \$100 and get 20% off on your total',
      },
      'review_pay_text': {
        'id': 2,
        'translation': 'Review & Pay',
      },
      'estimated_cost': {
        'id': 3,
        'translation': 'Estimated cost:',
      },
      'excluding_text': {
        'id': 4,
        'translation': '(Excluding add-ons and taxes)',
      },
      'search_by_job_name': {
        'id': 5,
        'translation': 'Search by Job Name',
      },
    },
    'promotion_module': {
      'title': {
        'id': 1,
        'translation': 'Create Promotion',
      },
      'title_text': {
        'id': 2,
        'translation': 'Title',
      },
      'image_upload_error': {
        'id': 3,
        'translation': 'Image upload error message',
      },
      'combined_promotion_ques': {
        'id': 4,
        'translation': 'Promotion can be combined with other promotions?',
      },
      'promotion_title': {
        'id': 5,
        'translation': 'Promotion Title',
      },
      'describe_promotion': {
        'id': 6,
        'translation': 'Describe about the promotion',
      },
      'enter_promotion_details': {
        'id': 7,
        'translation': 'Please enter promotion details',
      },
      'promotion_type': {
        'id': 8,
        'translation': 'Promotion Type',
      },
      'promotion_begin_date': {
        'id': 9,
        'translation': 'Promotion begin date',
      },
      'promotion_end_date': {
        'id': 10,
        'translation': 'Promotion end date',
      },
      'promotion_brodcast_range': {
        'id': 11,
        'translation': 'Promotion broadcast range',
      },
      'upload_promotion_pictures': {
        'id': 12,
        'translation': 'Upload promotional pictures',
      },
      'promotion_exp_values': {
        'id': 13,
        'translation': 'Promotion expiry value',
      },
      'reaching_sum_discount': {
        'id': 14,
        'translation': 'Promotion expire after reaching this sum Discount',
      },
      'usage_max_limit': {
        'id': 15,
        'translation': 'Promotion usage max limit',
      },
      'per_user_limit_promotion': {
        'id': 16,
        'translation': 'Per user limit on promotion',
      },
      'promotional_camping_desc': {
        'id': 17,
        'translation':
            'Select fixed discount amount you would like to offer in this promotional camping and select service(s) or item you may offer your consumers.',
      },
      'disc_amount': {
        'id': 18,
        'translation': 'Discount Amount',
      },
      'disc_amount_mandatory': {
        'id': 19,
        'translation': 'Discount Amount (Mandatory)',
      },
      'amount_text': {
        'id': 20,
        'translation': 'Amount',
      },
      'this_promotional_offer': {
        'id': 21,
        'translation':
            'Select fixed discount % you would like to create this Promotional offer',
      },
      'discount_age': {
        'id': 22,
        'translation': 'Discount %age',
      },
      'percentage_between': {
        'id': 23,
        'translation': 'Discount percentage between (1 - 100)% (Mandatory)',
      },
      'percentage_required': {
        'id': 24,
        'translation': 'Percentage is required',
      },
      'must_between_100': {
        'id': 25,
        'translation': 'Discount percentage must between 1 to 100',
      },
      'no_service_combination': {
        'id': 26,
        'translation': 'No Services available for this combination',
      },
      'apply_promotion_ques': {
        'id': 27,
        'translation': 'Apply promotion to all of your services?',
      },
      'walk_in_lable': {
        'id': 28,
        'translation': 'Walk-In',
      },
      'in_house_label': {
        'id': 29,
        'translation': 'In-House',
      },
      'on_Demand_label': {
        'id': 30,
        'translation': 'On-Demand',
      },
      'promotion_details': {
        'id': 31,
        'translation': 'Promotion Details',
      },
      'promotion_terms': {
        'id': 32,
        'translation': 'Promotion terms and conditions web page (If any)',
      },
      'type_web_page_url': {
        'id': 33,
        'translation': 'Type web page URL (make sure URL is working)',
      },
      'promotion_terms_conditions': {
        'id': 34,
        'translation': 'Promotion terms and conditions',
      },
      'business_prof_details': {
        'id': 35,
        'translation': 'Please enter business profile details',
      },
      'promotion_terms_mandatory': {
        'id': 36,
        'translation': 'Promotion terms and conditions (Mandatory)',
      },
      'have_agree_text': {
        'id': 37,
        'translation': 'I have read and agree to ',
      },
      'tudo_promotions_terms': {
        'id': 38,
        'translation': 'TUDO Promotions Terms and Conditions.',
      },
      'must_check_text': {
        'id': 39,
        'translation': 'You must accept terms and conditions to continue',
      },
      'create_txt': {
        'id': 40,
        'translation': 'Create',
      },
      'invoice_pay': {
        'id': 41,
        'translation': 'Invoice & Pay',
      },
      'succ_text': {
        'id': 42,
        'translation': 'Success',
      },
      'promotion_create_success': {
        'id': 43,
        'translation': 'Promotion created successfully',
      },
      'promotion_cost': {
        'id': 44,
        'translation': 'Promotion Cost:',
      },
      'share_text': {
        'id': 45,
        'translation': 'Share',
      },
      'promotion_applied': {
        'id': 46,
        'translation': 'Promotion applied in following services',
      },
      'promotion_images': {
        'id': 47,
        'translation': 'Promotion Images',
      },
      'description_text': {
        'id': 48,
        'translation': 'Description',
      },
      'broad_range': {
        'id': 49,
        'translation': 'Broadcast Range',
      },
      'promotion_pricing_text': {
        'id': 50,
        'translation': 'Promotion Pricing',
      },
      'base_unit_price': {
        'id': 51,
        'translation': 'Promotion base unit price',
      },
      'promotion_cost_text': {
        'id': 52,
        'translation': 'Promotion cost',
      },
      'tudo_disc': {
        'id': 53,
        'translation': 'TUDO discount',
      },
      'estimates_tax_txt': {
        'id': 54,
        'translation': 'Estimated tax',
      },
      'estimate_total_cost': {
        'id': 55,
        'translation': 'Estimated total cost',
      },
      'promotion_disc_details': {
        'id': 56,
        'translation': 'Promotion discount details',
      },
      'disc_details': {
        'id': 57,
        'translation': 'Discount details',
      },
      'promotion_usage_max_limit': {
        'id': 58,
        'translation': 'Promotion usage max value',
      },
      'per_user_limit': {
        'id': 59,
        'translation': 'Per user limit on promotion',
      },
      'share_text_label': {
        'id': 60,
        'translation': 'Share',
      },
      'status_text': {
        'id': 61,
        'translation': 'Status :',
      },
      'utilization_text': {
        'id': 62,
        'translation': 'Utilization',
      },
      'business_promotion': {
        'id': 63,
        'translation': 'Business Promotions',
      },
      'payment_process_like_to_Do': {
        'id': 64,
        'translation':
            'Payment procceed and Promotion created successfully!!\n\nShare with social media if you would like to!',
      },
      'share_with_social_media': {
        'id': 65,
        'translation': 'Share with social media',
      },
      'search_text': {
        'id': 65,
        'translation': 'Search',
      },
      'dont_avail_promotion': {
        'id': 66,
        'translation': 'You don\'t have any available promotion',
      },
      'please_purchase_seprate': {
        'id': 67,
        'translation':
            'Please purchase a new subscription or purchase promotion separately',
      },
      'purchase_text': {
        'id': 68,
        'translation': 'Purchase',
      },
      'any_available_promotion': {
        'id': 69,
        'translation': 'you don\'t have any available promotion!',
      },
      'next_text': {
        'id': 70,
        'translation': 'Next',
      },
      'not_now_text': {
        'id': 71,
        'translation': 'Not now',
      },
      'cutomer_not_exist': {
        'id': 72,
        'translation': 'customer doesn\'t exist',
      },
      'select_subscription': {
        'id': 73,
        'translation': 'Select a Subscription',
      },
      'select_plan_desc': {
        'id': 74,
        'translation': 'Select a plan and subscription to crate Promotion',
      },
      'paid_service_desc': {
        'id': 75,
        'translation':
            'This is paid service and promotion pricing may vary based on your promotional coverage area.',
      },
      'selected_plan': {
        'id': 76,
        'translation': 'Selected Plan',
      },
      'monthly_plan': {
        'id': 77,
        'translation': 'Monthly Plan',
      },
    },
    'add_walk_in_module': {
      'error_while_picking_text': {
        'id': 1,
        'translation': "You need to select exact one service to proceed next!",
      },
      'please_choose_img_text': {
        'id': 2,
        'translation': "Please choose Image",
      },
      'choose_an_image': {
        'id': 3,
        'translation': "Choose an Image",
      },
      'enter_first_name_text': {
        'id': 4,
        'translation': "Enter your First name",
      },
      'employee_first_name': {
        'id': 5,
        'translation': "Employee First Name",
      },
      'employee_last_name': {
        'id': 6,
        'translation': "Employee Last Name",
      },
      'employee_address_mandatory': {
        'id': 7,
        'translation': "Employee Address (Mandatory)",
      },
      'employee_address_text': {
        'id': 8,
        'translation': "Employee Address",
      },
      'employee_demographic_text': {
        'id': 9,
        'translation': "Employee Demographic info",
      },
      'please_select_all_required_fields': {
        'id': 10,
        'translation': "Please Select All Required Items With Profile Picture",
      },
      'employee_identification_title': {
        'id': 11,
        'translation': "Employee Identification",
      },
      'employee_list_title': {
        'id': 12,
        'translation': "Employee List",
      },
      'hey_there_text': {
        'id': 13,
        'translation': "Hey there !",
      },
      'scan_tudo_text': {
        'id': 14,
        'translation': "Scan Tudo QR code",
      },
      'take_new_employee': {
        'id': 15,
        'translation': "Take New Employee",
      },
      'employee_idnetity': {
        'id': 16,
        'translation': "Employee Identification",
      },
      'exact_one_service_to_next': {
        'id': 17,
        'translation': "You need to select exact one service to proceed next!",
      },
      'please_enter_valid_time': {
        'id': 18,
        'translation': "Please enter valid date and time",
      },
      'service_date_time': {
        'id': 19,
        'translation': "Service Date and Time",
      },
      'job_title': {
        'id': 20,
        'translation': "Job Title",
      },
      'upload_picture': {
        'id': 21,
        'translation': "Upload Pictures",
      },
      'error_in_finding_bsp': {
        'id': 22,
        'translation':
            "error in finding the Business Service Provider based on provided criteria",
      },
      'something_went_wrong': {
        'id': 23,
        'translation': "Something went wrong!",
      },
      'you_can_edit_address': {
        'id': 24,
        'translation':
            "You can edit address to include Apt#, Plot#, Floor#, Suit#, etc.",
      },
      'please_enter_address': {
        'id': 25,
        'translation': "Please select address",
      },
      'please_select_valid_time': {
        'id': 26,
        'translation': "Please select valid time!",
      },
      'service_type_text': {
        'id': 27,
        'translation': "Service Type",
      },
      'create_walk_in': {
        'id': 28,
        'translation': "Create Walk-in",
      },
      'walk_in_create_success': {
        'id': 29,
        'translation': "Walk-in Successfully Created",
      },
      'questionaries_response': {
        'id': 30,
        'translation': "Questionnaire response:",
      },
      'select_terms_conditions': {
        'id': 31,
        'translation': "Please Select Terms and Condition",
      },
      'job_details': {
        'id': 32,
        'translation': "Job Detail",
      },
      'service_information': {
        'id': 33,
        'translation': "Service information",
      },
    },
    'add_address_module': {
      'select_from_address_book': {
        'id': 1,
        'translation': "Select From Address Book",
      },
      'current_location': {
        'id': 2,
        'translation': "Current Location",
      },
      'address_text': {
        'id': 3,
        'translation': "Address",
      },
      'selected_services': {
        'id': 4,
        'translation': "Selected Service",
      },
      'appointment': {
        'id': 5,
        'translation': "Appointment",
      },
      'date_and_time': {
        'id': 6,
        'translation': "Date and Time",
      },
      'request_type': {
        'id': 7,
        'translation': "Request Type",
      },
      'service': {
        'id': 8,
        'translation': "Services",
      },
      'bsp_listview': {
        'id': 9,
        'translation': "Business Listview",
      },
      'sort': {
        'id': 10,
        'translation': "sort",
      },
    },
    'service_provider_map_screen': {
      'no_bsp_found': {
        'id': 1,
        'translation': 'No Business Service Providers Found!',
      },
      'distance_text': {
        'id': 2,
        'translation': 'Distance ',
      },
      'service_providers': {
        'id': 3,
        'translation': 'Service Providers',
      },
    },
    're_schedule_module': {
      'app_id': {
        'id': 1,
        'translation': "Appt.ID:",
      },
      'schedule': {
        'id': 2,
        'translation': "Schedule",
      },
      'appt_type': {
        'id': 3,
        'translation': "Appnt. Type:",
      },
      'spend_txt': {
        'id': 4,
        'translation': "Spend",
      },
      'get_off_desc': {
        'id': 5,
        'translation': "and get 20% off on your total",
      },
      'cost_estimates': {
        'id': 6,
        'translation': "Cost estimated:",
      },
      'plus_taxes': {
        'id': 7,
        'translation': "(plus applicable Taxes)",
      },
      'enter_re_schedule_reason': {
        'id': 8,
        'translation': "Please enter Re-Schedule Reason",
      },
      're_schedule_reason': {
        'id': 9,
        'translation': "Write Re-Schedule reason",
      },
      're_schedule_text': {
        'id': 10,
        'translation': "Re Schedule",
      },
      'service_provider_must_accept': {
        'id': 11,
        'translation':
            "Note: Service provider must accept your requested change and wait for confirmation.",
      },
      're_schedule': {
        'id': 12,
        'translation': "Re-Schedule",
      },
      'select_new_time': {
        'id': 13,
        'translation': "Please select a new date and time",
      },
      'rescheduled_success': {
        'id': 14,
        'translation': "Walk-in Successfully Re-Scheduled",
      },
    },
    'cancel_appointment_screen': {
      'enter_cancellation_text': {
        'id': 1,
        'translation': "Please enter Cancelation Reason",
      },
      'write_cancellation_reason': {
        'id': 2,
        'translation': "Write Cancellation reasons",
      },
      'cancel_appointment': {
        'id': 3,
        'translation': "Cancel Appointment",
      },
      'cancellation_fees_text': {
        'id': 4,
        'translation':
            "Are you sure to cancel this service appointment?Service provider may impose \$5 cancellation fee.",
      },
      'confirmation': {
        'id': 5,
        'translation': "Confirmation",
      },
    },
    'deal_module': {
      'deals_offers': {
        'id': 1,
        'translation': "Deals & Offers",
      },
      'deal_schedule': {
        'id': 2,
        'translation': "Deal Schedule",
      },
      'job_title': {
        'id': 3,
        'translation': "Job Title",
      },
      'job_title_required': {
        'id': 4,
        'translation': "Job Title is required",
      },
      'please_select_date_time': {
        'id': 5,
        'translation': "Please select Date and Time",
      },
      'no_deals_found': {
        'id': 6,
        'translation': "No Deals Found!",
      },
    },
    'forgot_password': {
      'time_is_expired': {
        'id': 1,
        'translation': "Time is Expired",
      },
      'your_time_exp_text': {
        'id': 2,
        'translation':
            "Your time is expired please press reset button to get a new OTP",
      },
      'resend_the_otp': {
        'id': 3,
        'translation': "Resend the otp",
      },
      'success_password_change': {
        'id': 4,
        'translation': "You have Successfully Change Password",
      },
      'fill_all_cell_text': {
        'id': 5,
        'translation':
            "*Please fill up all the cells with valid code and press VERIFY again",
      },
      'verify_txt': {
        'id': 6,
        'translation': "VERIFY",
      },
      'resend_otp_text': {
        'id': 7,
        'translation': "Didn't receive the code? Resend",
      },
      'wait': {
        'id': 8,
        'translation': "Wait",
      },
      'enter_password_text': {
        'id': 9,
        'translation': "Enter your Password",
      },
      'new_pass_text': {
        'id': 10,
        'translation': "New Password",
      },
      'update_pass_text': {
        'id': 11,
        'translation': "Update Password",
      },
      'forgot_pass_text': {
        'id': 12,
        'translation': "Forgot Password",
      },
    },
    'job_module': {
      'job_address_not_found': {
        'id': 1,
        'translation': "JOB ADDRESS NOT FOUND",
      },
      'change_details': {
        'id': 2,
        'translation': "Change details",
      },
      'new_schedule': {
        'id': 3,
        'translation': "New schedule: ",
      },
      'change_reason': {
        'id': 4,
        'translation': "Change reason: ",
      },
      'appt_txt': {
        'id': 6,
        'translation': "Appt.#",
      },
      'app_type_text': {
        'id': 7,
        'translation': "Appt. Type:",
      },
      'token_text': {
        'id': 8,
        'translation': "Token#",
      },
    },
    'employee_enrollement_module': {
      'employee_enrollment': {
        'id': 1,
        'translation': "Employee Enrollment",
      },
      'previous_text': {
        'id': 2,
        'translation': "Previous",
      },
      'enrollment_completed': {
        'id': 3,
        'translation': "Enrollment Completed Successfully",
      },
      'hey_text': {
        'id': 4,
        'translation': "Hey,",
      },
      'recived_invitations': {
        'id': 6,
        'translation':
            "You received invitation from the Business Service Provider Ravindra",
      },
      'accept_text': {
        'id': 7,
        'translation': "Accept",
      },
      'accept_invitation_ques': {
        'id': 8,
        'translation': "Are you sure to accept invitation?",
      },
      'reject_text': {
        'id': 9,
        'translation': "Reject",
      },
      'reject_invitations_ques': {
        'id': 10,
        'translation': "Are you sure to reject invitation?",
      },
    },
    'dashboard_screen': {
      'sort_by': {
        'id': 1,
        'translation': "Sort By",
      },
      'unselected_sort': {
        'id': 2,
        'translation': "Unselected Sort",
      },
      'are_you_running_business': {
        'id': 3,
        'translation': "Are You Running a Business?",
      },
      'consumer_success_popup_text1': {
        'id': 4,
        'translation':
            'Congratulations!! Your TUDO Consumer account created successfully. ',
      },
      'consumer_success_popup_text2': {
        'id': 5,
        'translation':
            'TUDO app aims at Businesses bridging gaps between Business Service Providers and Consumers collaborate on the unique technology platform.',
      },
      'consumer_success_popup_text3': {
        'id': 6,
        'translation': 'Any questions? Reach us @1-800-888-TUDO',
      },
      'service_details': {
        'id': 7,
        'translation': 'Service Details',
      },
    },
    'services_help_screen': {
      'title': {
        'id': 1,
        'translation': "Service Help",
      },
      'main_header_text': {
        'id': 2,
        'translation':
            "Tell TUDO about the nature of your Business by selecting all the applicable services you business offers to your Consumers.",
      },
      'walk_in_desc': {
        'id': 3,
        'translation':
            "Consumer walks/visits/enters into your business location/office/storefront to avail the services your business offered in the marketplace. Example: Salon, Hospital, Accounting, Dry-cleaners and so.",
      },
      'in_house_desc': {
        'id': 4,
        'translation':
            'You or your business representative visits Consumer\'s service address such their home/ office/ business, etc.to render your services. Example: Home Cleaning, Handyman, Plumber, Electrician, Caretaker, etc.',
      },
      'on_demand_desc': {
        'id': 5,
        'translation':
            'You or your business representative reaches to Consumer\'s current geolocation with in 30 minutes to render your services. Example: Taxi, Roadside assistance, Emergency repairs, any location-based services, Ambulance, Public services, etc.',
      },
    },
    'my_preference_screen': {
      'title': {
        'id': 1,
        'translation': "My Preferences",
      },
      'auto_refresh_mode': {
        'id': 2,
        'translation': "Dashboard auto refresh mode",
      },
      'share_my_location_text': {
        'id': 3,
        'translation': "Share my location with other TUDO users",
      },
      'profile_visibility': {
        'id': 4,
        'translation': 'My profile visibility to other TUDO users',
      },
      'notifications_settings': {
        'id': 5,
        'translation': 'Notifications Settings',
      },
      'send_notifications': {
        'id': 6,
        'translation': 'Send notifications',
      },
      'send_emails': {
        'id': 7,
        'translation': 'Send emails',
      },
      'dashboard_question': {
        'id': 8,
        'translation': "Are you sure to change dashboard auto refresh mode?",
      },
      'location_question': {
        'id': 9,
        'translation': "Are you sure to change your location sharing mode with other TUDO users?",
      },
      'profile_visibility_question': {
        'id': 10,
        'translation': "Are you sure to change your profile visibility mode with other TUDO users?",
      },
      'notifications_question': {
        'id': 11,
        'translation': 'Are you sure to change send notifications settings?',
      },
      'emails_questions': {
        'id': 12,
        'translation': 'Are you sure to change send emails settings?',
      },
      'dashboard_success': {
        'id': 13,
        'translation': 'Dashboard auto refresh mode updated successfully',
      },
      'locatioon_success': {
        'id': 14,
        'translation': 'Share your locations with other TUDO users settings updated successfully',
      },
      'visibility_success': {
        'id': 15,
        'translation': 'Profile visibility settings updated successfully',
      },
      'notification_success': {
        'id': 16,
        'translation': 'Send notifications settings updated successfully',
      },
      'emails_success': {
        'id': 17,
        'translation': 'Send emails settings updated successfully',
      },
    },
    'validation_errors':{
      'required': {
        'id': 17,
        'translation': '$field is required',
      },
      'alphanumeric': {
        'id': 17,
        'translation': '$field must be alphanumeric.',
      },
      'alpha': {
        'id': 17,
        'translation': '$field must be alphabetic (a-zA-Z).',
      },
      'numeric': {
        'id': 17,
        'translation': '$field must be numeric.',
      }
    },
    "branch_status": {
      'confirmed': {
        'id': 17,
        'translation': 'Confirmed',
      },
      'admin_confirmation_pending': {
        'id': 17,
        'translation': 'Confirmation Pending',
      },
      'success_update': {
        'id': 17,
        'translation': 'Branch Status updated successfully',
      },
      'error_update': {
        'id': 17,
        'translation': 'Branch Status not updated successfully',
      },
    }
  };


  transform(value: String, ...args: unknown[]): unknown {
    
    let field  = value.split('.');
    
 
    let transValue:String = this.getValueFromSlug(field);

    if(args.length && transValue) {
      let fieldName = this.getValueFromSlug((args[0] as String).split('.'));
 
      if(fieldName) {
        transValue = transValue.replace('$field',fieldName);
      }
    }

    return transValue;
   
  }

  getValueFromSlug(field) {
    let temp = null;
    temp = {...this.trans};

    for(let i =0 ; i < field.length ; i++) {
      if(temp) {
        temp = temp[field[i]];
      } 

      if(i == (field.length - 1)) {
        temp = temp['translation'];
      }
    }

    return temp;
  }

}
