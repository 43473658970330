import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  _loginUser;

  constructor(
    private _fuseNavigationService: FuseNavigationService
  ) { }

  isAuthenticated() {
    let user = this.getUser();
    return user != null;
  }

  setLoginUser(user,fcm) {
    this._loginUser = user;
    localStorage.setItem("token",user.token);
    localStorage.setItem("fcmToken",fcm);
    localStorage.setItem("auth",JSON.stringify(user)); 
  }

  getUser() {
    let user = this._loginUser ;
     if(user == null) {
        user = localStorage.getItem('auth');

        if(user != null) {
           this._loginUser = JSON.parse(user);
           user = this._loginUser;
        }
     } 
     return user;
  }

  getToken(){
   let user  = this.getUser();
   return user != null ? user.token : null;
  }

  get getCountry() {
    let user = this.getUser();
    return  user != null ? user.country : null;
  }

  logOut() {
    this._loginUser = null;
    this._fuseNavigationService.removeNavigationItem('aminDashboard');
    localStorage.clear();
  }


  
}
